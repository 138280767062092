import React, { useState, useEffect, useMemo } from "react"

import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Flex,
  Text,
  IconButton,
  Link,
} from "@chakra-ui/react"

import { Link as GatsbyLink } from "gatsby"

import { NAV_ITEMS } from "../../Navbar"

import { SinglePageMenu, GroupByCategoryMenu } from "./submenus"

import { MobileActiveIndicatorIcon, BlickeSvg } from "../../svgs"

const MobileNav = ({ data, isOpen, setIsOpen }) => {
  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  const [tabIndex, setTabIndex] = useState(0)

  const handleTabsChange = index => {
    setTabIndex(index)
  }

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("noscroll")
    } else {
      document.body.classList.remove("noscroll")
    }
  }, [isOpen])

  return (
    <>
      <Popover
        trigger="click"
        variant="responsive"
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={close}
        closeOnBlur={true}
      >
        <PopoverTrigger>
          <Flex align="center" mr={4}>
            <IconButton
              onClick={open}
              icon={isOpen ? <CloseMenuIcon /> : <OpenMenuIcon />}
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>
        </PopoverTrigger>
        <Portal>
          <Box
            position="absolute"
            display={{ md: "none" }}
            top={0}
            left={0}
            width="100vw"
            height={isOpen ? "100vh" : 0}
            zIndex={1000}
          >
            <PopoverContent
              display={{ base: "flex", md: "none" }}
              position="absolute"
              overflowY="hidden"
              w="100vw"
              h="calc(100vh - 85px)"
              left="50%"
              top="10px"
              bg="primary"
              transform="translateX(-50%)!important"
              minHeight={"100px"}
              borderRadius={0}
              border={0}
              _focus={{ outline: "none" }}
            >
              <Box display={{ base: "block", md: "none" }}>
                <Tabs
                  align="center"
                  index={tabIndex}
                  onChange={handleTabsChange}
                  border={0}
                >
                  <TabList
                    pos="relative"
                    overflowY="hidden"
                    backgroundColor="dark_blue"
                    border={0}
                    px={4}
                    pb={8}
                    justifyContent="flex-start"
                  >
                    {NAV_ITEMS.map(navItem => {
                      const isActiveLink = NAV_ITEMS[tabIndex] === navItem
                      return (
                        <Tab
                          key={navItem}
                          _selected={{
                            fontWeight: "bold",
                            fontFamily: "CocoSharpL-Bold",
                          }}
                          _focus={{ outline: "none" }}
                          position="relative"
                          px={4}
                        >
                          {navItem === "Blicke" ? (
                            <Box w="4rem" pb="6px">
                              <BlickeSvg color="#fff" />
                            </Box>
                          ) : (
                            <Text as="span" color="white">
                              {navItem}
                            </Text>
                          )}
                          <Box
                            display={!isActiveLink && "none"}
                            pos="absolute"
                            bottom="-2.1rem"
                            left="50%"
                            w="100%"
                            transform="translateX(-50%)"
                            zIndex={2}
                            sx={{
                              svg: {
                                width: "100%",
                              },
                            }}
                          >
                            <MobileActiveIndicatorIcon />
                          </Box>
                        </Tab>
                      )
                    })}
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      px={4}
                    >
                      <Link as={GatsbyLink} to="/ueber-uns" color="white">
                        Über
                      </Link>
                    </Box>
                  </TabList>

                  <TabPanels>
                    {NAV_ITEMS.map((navItem, index) => {
                      return (
                        <TabPanel
                          key={NAV_ITEMS[index].toLowerCase()}
                          p={0}
                          overflowY="scroll"
                          position="relative"
                          h="100%"
                        >
                          <PanelSwitch
                            data={data}
                            navItem={navItem.toLowerCase()}
                            tabIndex={index}
                          />
                        </TabPanel>
                      )
                    })}
                  </TabPanels>
                </Tabs>
              </Box>
            </PopoverContent>
          </Box>
        </Portal>
      </Popover>
    </>
  )
}

const PanelSwitch = ({ data, navItem, tabIndex }) => {
  const articles = useMemo(() => data[navItem].nodes, [data, navItem])

  switch (NAV_ITEMS[tabIndex]) {
    case "Bereiche":
      return <SinglePageMenu data={articles} />
    case "Blicke":
      return <SinglePageMenu isBlickeMenu data={articles} />
    case "Projekte":
      return <GroupByCategoryMenu data={articles} type={NAV_ITEMS[tabIndex]} />
    case "Daten":
      return <GroupByCategoryMenu data={articles} type={NAV_ITEMS[tabIndex]}  disableYearSelect />
    default:
      return null
  }
}

export default MobileNav

const CloseMenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.052"
    height="30.052"
    viewBox="0 0 30.052 30.052"
  >
    <g
      id="Group_1591"
      data-name="Group 1591"
      transform="translate(-1059.797 -40.651)"
    >
      <line
        id="Line_1"
        data-name="Line 1"
        x1="37"
        transform="translate(1088.081 42.419) rotate(135)"
        fill="none"
        stroke="#fff"
        strokeWidth="5"
      />
      <line
        id="Line_3"
        data-name="Line 3"
        x1="37"
        transform="translate(1061.565 42.772) rotate(45)"
        fill="none"
        stroke="#fff"
        strokeWidth="5"
      />
    </g>
  </svg>
)

const OpenMenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="30"
    viewBox="0 0 37 30"
  >
    <g
      id="Group_1591"
      data-name="Group 1591"
      transform="translate(-1056.5 -41)"
    >
      <line
        id="Line_1"
        data-name="Line 1"
        x1="37"
        transform="translate(1056.5 43.5)"
        fill="none"
        stroke="#315895"
        strokeWidth="5"
      />
      <line
        id="Line_2"
        data-name="Line 2"
        x1="37"
        transform="translate(1056.5 56.5)"
        fill="none"
        stroke="#315895"
        strokeWidth="5"
      />
      <line
        id="Line_3"
        data-name="Line 3"
        x1="37"
        transform="translate(1056.5 68.5)"
        fill="none"
        stroke="#315895"
        strokeWidth="5"
      />
    </g>
  </svg>
)
