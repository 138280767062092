import React from "react"

import { Flex, Heading, Stack, Text, Box, Link } from "@chakra-ui/react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { ArrowUp } from "../../layout/Footer"

const Hero = ({ data }) => {
  const { titel, text, bild } = data

  const image = bild && getImage(bild.localFile)

  return (
    <Box
      as="section"
      mb={8}
      width="100vw"
      top={0}
      left="50%"
      right="50%"
      marginLeft="-50vw"
      marginRight="-50vw"
      position="relative"
    >
      <Stack maxW="7xl" w="100%" mx="auto" h="100%" position="relative" py={8}>
        <Flex
          mx="auto"
          position="relative"
          direction={{ base: "column", md: "row" }}
          maxW="1100px"
          w="100%"
        >
          <Stack
            w="full"
            maxW={{ md: "2xl" }}
            mb={{ base: "1rem", md: 16 }}
            mr={{ md: 12 }}
            mt={{ md: 8 }}
            px={4}
          >
            <Heading
              // fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
              fontFamily="CocoSharp-Bold"
              color="white"
              fontSize="2.5rem"
              lineHeight="2.5625rem"
              letterSpacing="1px"
              mb={4}
            >
              {titel}
            </Heading>
            <Text
              dangerouslySetInnerHTML={{ __html: text }}
              color="white"
              pr={{ md: 8 }}
              lineHeight="1.3125rem"
            />
          </Stack>
          {bild && (
            <Flex
              alignItems="center"
              maxW="500px"
              w="100%"
              mt={{ base: 4, md: 0 }}
              px={{ base: 4, md: 0 }}
            >
              <GatsbyImage
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  maxHeight: "380px",
                }}
                objectFit="contain"
                image={image}
                alt={bild.altText}
              />
            </Flex>
          )}
          <Box
            display={{ base: "none", md: "initial" }}
            position="absolute"
            bottom="-55px"
            left={{ base: 4 }}
            zIndex={3}
            cursor="pointer"
            transition="all 0.3s ease-out"
            role="group"
            _hover={{
              textDecoration: "none",
            }}
            as={Link}
            href="#downloads"
            aria-label="Link zur PDF Download-Sektion"
          >
            <Text color="white">Downloads</Text>
            <Box
              padding={4}
              _groupHover={{
                backgroundColor: "dark_blue",
              }}
              w="90px"
              h="90px"
              display="flex"
              transition="background-color 0.3s ease-out"
              justifyContent="center"
              alignItems="center"
              transform="rotate(180deg)"
              backgroundColor="primary"
              borderRadius="50%"
            >
              <ArrowUp />
            </Box>
          </Box>
        </Flex>
      </Stack>

      <Box
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          bottom: 0,
          zIndex: -1,
          svg: {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <Radial />
      </Box>
    </Box>
  )
}

export default Hero

const Radial = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1080"
    height="295.262"
    viewBox="0 0 1080 295.262"
    preserveAspectRatio="none"
  >
    <defs>
      <radialGradient
        id="radial-gradient"
        cx="0.642"
        cy="0.751"
        r="0.872"
        gradientTransform="matrix(0.454, -0.891, 0.35, 0.178, 0.088, 1.189)"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#002149" />
        <stop offset="1" stopColor="#005a98" />
      </radialGradient>
    </defs>
    <path
      id="Path_2499"
      data-name="Path 2499"
      d="M0,0H1080V295.262H0Z"
      fill="url(#radial-gradient)"
    />
  </svg>
)
