import React from "react"
import { HStack, Text, Center } from "@chakra-ui/react"

export const Header = ({ years, currentYear, setCurrentYear }) => (
  <HStack justify="center" mt={5} mb={2} spacing={0}>
    {years.map(year => {
      const isCurrentYear = currentYear === year

      return (
        <Center
          px={5}
          w="70px"
          py={0}
          key={year}
          pos="relative"
          zIndex={10}
          onClick={() => setCurrentYear(year)}
          cursor="pointer"
          backgroundColor={isCurrentYear && "secondary"}
          border="1px solid #fff"
        >
          <Text
            as="span"
            color={isCurrentYear ? "dark_blue" : "white"}
            fontWeight={"bold"}
            fontFamily="CocoSharpL-Bold"
            fontSize="md"
            letterSpacing="widest"
            mt={1}
          >
            {year}
          </Text>
        </Center>
      )
    })}
  </HStack>
)
