// Defining the colors used for each category
const colors = {
  Wasser: "#95C266",
  Energie: "#FFD533",
  "Nachhaltigkeit, Vorbild Land": "#1A8ED3", // new
  "Klimaschutz & Klimawandelanpassung": "#E9C542", // new
  "Konsum & nachhaltige Beschaffung": "#68AE44", // new
  "Nachhaltige Wirtschaft, Umwelttechnologie & Infrastruktur": "#F2DD20", // new
  "Raumordnung & Mobilität": "#BCD233", // new
  "Bildung, Wissenschaft & Arbeit": "#CD5B77", // new
  "Naturschutz & Biodiversität": "#BC559F", // new
  "Menschen im Land": "#C73F32", // new
  "Abfallwirtschaft & Kreislaufwirtschaft": "#7EBCB1", // new
  "Landwirtschaft, Forstwirtschaft, Bodenschutz": "#DB9E27", // new
  Other: "#f0f7fb",
  // old categories from here
  "Gouvernance & SDG": "#1A8ED3",
  "Nachhaltiger Konsum": "#68AE44",
  "Wirtschaft & Digitalisierung": "#F2DD20",
  "Mobilität & Raumentwicklung": "#BCD233",
  "Bildung & Wissenschaft": "#CD5B77",
  Naturschutz: "#BC559F",
  "Leben am Land": "#C73F32",
  Kreislaufwirtschaft: "#7EBCB1",
  "Land- & Forstwirtschaft": "#DB9E27",
  Klima: "#E9C542",
}

// Defining the order by which the categories are sorted in the navigation menu
const order = {
  "Abfallwirtschaft & Kreislaufwirtschaft": 1, // new
  "Konsum & nachhaltige Beschaffung": 2, // new
  Wasser: 3,
  "Raumordnung & Mobilität": 4, // new
  "Nachhaltige Wirtschaft, Umwelttechnologie & Infrastruktur": 5, // new
  Energie: 6,
  "Klimaschutz & Klimawandelanpassung": 7, // new
  "Landwirtschaft, Forstwirtschaft, Bodenschutz": 8, // new
  "Menschen im Land": 9, // new
  "Bildung, Wissenschaft & Arbeit": 10, // new
  "Naturschutz & Biodiversität": 11, // new
  "Nachhaltigkeit, Vorbild Land": 12, // new
  Other: 13,
  // old categories from here
  Kreislaufwirtschaft: 1,
  "Nachhaltiger Konsum": 2,
  "Mobilität & Raumentwicklung": 4,
  "Wirtschaft & Digitalisierung": 5,
  Klima: 7,
  "Land- & Forstwirtschaft": 8,
  "Leben am Land": 9,
  "Bildung & Wissenschaft": 10,
  Naturschutz: 11,
  "Gouvernance & SDG": 12,
}

export { colors, order }
