import React, { useMemo, useState } from "react"

import { Link as GatsbyLink } from "gatsby"

import {
  VStack,
  Text,
  Link,
  useDisclosure,
  Stack,
  Flex,
  Icon,
  Collapse,
  HStack,
  Box,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react"

import { ChevronDownIcon, ArrowForwardIcon } from "@chakra-ui/icons"

import CategorySVGSwitch from "../../../../../config/svgs"
import { order } from "../../../../../config/categories"

import { Header } from "./components"
import { formatFunnelUri } from "../../../../utils/format-funnel-uri"

const GroupByCategoryMenu = ({ data, type, disableYearSelect = false  }) => {
  const years = [
    ...new Set(data.map(item => item.pagebuilder.fixeElementeTop.jahr)),
  ]
  const sortedYears = years.length > 1 ? years.sort((a, b) => b - a) : years

  const [currentYear, setCurrentYear] = useState(
    sortedYears[sortedYears.length - 1]
  )

  const filteredData = disableYearSelect ? data : data.filter(
    a => a.pagebuilder.fixeElementeTop.jahr === currentYear
  )

  const categorizedData = useMemo(() => {
    const categorizedData = {}

    filteredData.forEach(item => {
      item.pagebuilder.fixeElementeTop.kategorie.forEach(cat =>
        categorizedData[cat]
          ? categorizedData[cat].push(item)
          : (categorizedData[cat] = [item])
      )
    })

    return categorizedData
  }, [filteredData])


  return (
    <>
      {!disableYearSelect && <Header
        years={sortedYears}
        currentYear={currentYear}
        setCurrentYear={setCurrentYear}
      />}
      <VStack
        w="100%"
        spacing={0}
        align="normal"
        pt={4}
        pb={12}
        overflowY="scroll"
        h="calc(100vh - 12rem)"
        px={4}
      >
        {Object.entries(categorizedData)
          .sort((a, b) => order[a[0]] - order[b[0]]) // Sort array by category order defined in config
          .map(([key, value]) => {
            return (
              <MobileNavItem
                key={key}
                title={key}
                value={value}
                jahr={currentYear}
                type={type}
                disableYearSelect={disableYearSelect}
              />
            )
          })}
      </VStack>
    </>
  )
}

export default GroupByCategoryMenu

const MobileNavItem = ({ title, value, jahr, type , disableYearSelect}) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <>
      <Stack
        spacing={0}
        onClick={value && onToggle}
        borderBottom="1px solid white"
      >
        <Flex
          py={4}
          justify="space-between"
          align={"center"}
          _hover={{
            textDecoration: "none",
          }}
          width="100%"
          flex={1}
        >
          <HStack>
            <Box w="35px" h="35px" flex="0 0 auto" flexShrink={0} mr={2}>
              {CategorySVGSwitch(title)}
            </Box>
            <Text
              color="white"
              textAlign="left"
              fontFamily="CocoSharp-Regular"
              fontSize="1.375rem"
              lineHeight="1.6rem"
            >
              {title}
            </Text>
          </HStack>
          <Box>
            <Icon
              as={ChevronDownIcon}
              color="white !important"
              transition={"all .25s ease-in-out"}
              transform={isOpen && "rotate(180deg)"}
              w={8}
              h={8}
            />
          </Box>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <Flex align="flex-start" px={4} mb={4}>
            <List spacing={2} pl={16}>
              <ListItem
                color="white"
                fontSize="0.95rem"
                textAlign="left"
                lineHeight="1.2rem"
                fontWeight="bold"
              >
                <ListIcon as={ArrowForwardIcon} color="white" ml={-6} />
                <Link as={GatsbyLink} to={formatFunnelUri(type, disableYearSelect ? undefined :  jahr, title)}>
                  Alle {type} im Überblick
                </Link>
              </ListItem>
              {value.map(item => (
                <ListItem
                  color="white"
                  key={item.uri}
                  fontSize="0.95rem"
                  textAlign="left"
                  lineHeight="1.2rem"
                >
                  <ListIcon as={ArrowForwardIcon} color="white" ml={-6} />
                  <Link as={GatsbyLink} to={item.uri}>
                    {item.title}
                  </Link>
                </ListItem>
              ))}
            </List>
          </Flex>
        </Collapse>
      </Stack>
    </>
  )
}
