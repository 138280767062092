import React from "react"
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby"
import { Box, Flex, Link, Text } from "@chakra-ui/react"

import { StaticImage } from "gatsby-plugin-image"
import { UmweltSvg } from "./svgs"

import { Downloads } from "../sections/landing"

const footerMenu = [
  { title: "Magazin bestellen", link: "/blicke-bestellen" },
  { title: "Impressum", link: "/impressum" },
  { title: "Datenschutz", link: "/datenschutzerklaerung" },
  { title: "Barrierefreiheit", link: "/barrierefreiheit" },
  { title: "Kontakt", link: "/kontakt" },
]

const UpperFooter = () => (
  <Box width="100%" height="100%" backgroundColor="primary">
    <Flex
      position="relative"
      maxW={"7xl"}
      mx="auto"
      height="100%"
      flexDirection={{ base: "column", md: "row " }}
      py={{ base: 8 }}
      px={4}
    >
      <Flex flexDirection={{ base: "column", md: "row " }} flex={1}>
        {footerMenu.map(({ title, link }, index) => {
          return (
            <Link
              as={GatsbyLink}
              key={title}
              fontFamily="CocoSharpL-Bold"
              fontSize="0.875rem"
              marginTop={{ base: index !== 0 && "1rem", md: "0" }}
              marginLeft={{
                base: "0",
                md: index !== 0 && "1rem",
                lg: index !== 0 && "3rem",
              }}
              textAlign={{ md: "left" }}
              color="white"
              lineHeight="1.3125rem"
              letterSpacing="1px"
              to={link}
              textTransform="uppercase"
            >
              {title}
            </Link>
          )
        })}
      </Flex>
    </Flex>
  </Box>
)

const LowerFooter = () => (
  <Box width="100%" height="100%" bg="white">
    <Box position="absolute" bottom={0} left={0} right={0} height="107px">
      <Flex>
        <Link
          href="https://www.noe.gv.at/noe/Umweltschutz/Umweltschutz.html"
          target="_blank"
          rel="noopener noreferrer"
          pos="relative"
          zIndex="10"
          display="inline-block"
          h="100%"
          mr={2}
          aria-label="Logo Link zur Umwelschutzshomepage vom Land Niederösterreich"
        >
          <UmweltSvg />
        </Link>
        <Link
          href="https://www.noe.gv.at/noe/Umweltschutz/EMAS_Landhaus.html"
          target="_blank"
          rel="noopener noreferrer"
          display="inline-block"
          pos="relative"
          zIndex="10"
          margin={0}
          paddingTop={2}
          aria-label='Logo Link zu "10 Jahre EMAS im NÖ Landhaus"'
        >
          <StaticImage
            src={"../../static/emas-logo.png"}
            alt="Emas Logo"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "95px",
              objectFit: "contain",
              verticalAlign: "middle",
            }}
            imgStyle={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Link>
      </Flex>
    </Box>
    <Flex
      position="relative"
      height="107px"
      flexDirection={{ base: "column", md: "row " }}
    >
      <Box
        display="flex"
        flex={1}
        px={4}
        justifyContent={{ base: "flex-start", lg: "flex-end" }}
        alignItems={{ base: "", lg: "center" }}
        paddingTop={{ base: "140px", md: "140px", lg: 0 }}
        paddingBottom={{ base: "80px", md: "60px", lg: 0 }}
      >
        <Link
          href="https://www.noe.gv.at/noe/index.html"
          target="_blank"
          rel="noopener noreferrer"
          textDecorationColor="var(--chakra-colors-primary) !important"
        >
          <Text as="p" color="primary" fontFamily="CocoSharpL-Regular">
            © 2022 Amt der NÖ Landesregierung
          </Text>
        </Link>
      </Box>
    </Flex>
  </Box>
)

export const ArrowUp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42.779"
    height="39.709"
    viewBox="0 0 42.779 39.709"
  >
    <g
      id="Group_1534"
      data-name="Group 1534"
      transform="translate(0.707 39.709) rotate(-90)"
    >
      <g id="Group_330" data-name="Group 330" transform="translate(0)">
        <line
          id="Line_42"
          data-name="Line 42"
          y1="39"
          transform="translate(39 20.682) rotate(90)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
        <line
          id="Line_41"
          data-name="Line 41"
          y1="29.25"
          transform="translate(18.318) rotate(-45)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
        <line
          id="Line_45"
          data-name="Line 45"
          y1="29.25"
          transform="translate(39.002 20.682) rotate(45)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
)

const ScrollUpButton = () => (
  <Box
    position="absolute"
    right="20px"
    top="-50px"
    zIndex={3}
    cursor="pointer"
    transition="all 0.3s ease-out"
    role="group"
    _hover={{
      textDecoration: "none",
    }}
    as={Link}
    href="#"
    aria-label="Scrollen Sie hier zurück nach oben"
  >
    <Box
      padding={6}
      borderRadius="50%"
      backgroundColor="primary"
      transition="background-color 0.3s ease-out"
      _groupHover={{
        backgroundColor: "dark_blue",
      }}
    >
      <ArrowUp />
    </Box>
    <Text as="span" color="white">
      nach oben
    </Text>
  </Box>
)

const Footer = () => {
  const data = useStaticQuery(query)
  const { headline, downloads } = data.frontpage.landingpage

  return (
    <>
      <Downloads
        data={{
          headline,
          downloads,
        }}
      />
      <Box
        as="footer"
        //   mt={12}
        width="100vw"
        top={0}
        left="50%"
        right="50%"
        marginLeft="-50vw"
        marginRight="-50vw"
        position="relative"
        padding={0}
      >
        <UpperFooter />
        <ScrollUpButton />
        <LowerFooter />
      </Box>
    </>
  )
}

export default Footer

const query = graphql`
  query DownloadsBottom {
    frontpage: wpPage(isFrontPage: { eq: true }) {
      landingpage {
        headline
        downloads {
          text
          datei {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
