exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-bereiche-daten-projekte-js": () => import("./../../../src/templates/bereiche-daten-projekte.js" /* webpackChunkName: "component---src-templates-bereiche-daten-projekte-js" */),
  "component---src-templates-blicke-js": () => import("./../../../src/templates/blicke.js" /* webpackChunkName: "component---src-templates-blicke-js" */),
  "component---src-templates-content-js": () => import("./../../../src/templates/content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-templates-funnel-daten-js": () => import("./../../../src/templates/funnel_daten.js" /* webpackChunkName: "component---src-templates-funnel-daten-js" */),
  "component---src-templates-funnel-projekte-js": () => import("./../../../src/templates/funnel_projekte.js" /* webpackChunkName: "component---src-templates-funnel-projekte-js" */)
}

