import React, { useState } from "react"

import { Link as GatsbyLink } from "gatsby"

import { VStack, Text, Link, HStack, Box, Center, Flex } from "@chakra-ui/react"

import CategorySVGSwitch from "../../../../../config/svgs"
import { order } from "../../../../../config/categories"

import { Header } from "./components"
import { StaticImage } from "gatsby-plugin-image"

const SinglePageMenu = ({ data, isBlickeMenu = false }) => {
  // Create array with unique years of all articles
  const years = [
    ...new Set(data.map(item => item.pagebuilder.fixeElementeTop.jahr)),
  ]

  const sortedYears = years.length > 1 ? years.sort((a, b) => b - a) : years

  const [currentYear, setCurrentYear] = useState(years[0])

  // Filter data by currently selected year and sort by category order defined in user config
  const filteredData = data
    .filter(a => a.pagebuilder.fixeElementeTop.jahr === currentYear)
    .filter(a => a.pagebuilder.fixeElementeTop.kategorie !== null)
    .sort(
      (a, b) =>
        order[a.pagebuilder.fixeElementeTop.kategorie[0]] -
        order[b.pagebuilder.fixeElementeTop.kategorie[0]]
    )

  return (
    <>
      <Header
        years={sortedYears}
        currentYear={currentYear}
        setCurrentYear={setCurrentYear}
      />
      <VStack
        align="flex-start"
        w="100%"
        spacing={0}
        pb={12}
        pt={2}
        overflowY="scroll"
        h="calc(100vh - 12rem)"
        px={4}
      >
        {filteredData.map((item, index) => {
          const { kategorie } = item.pagebuilder.fixeElementeTop

          return (
            <Link
              key={index}
              as={GatsbyLink}
              to={item.uri}
              w="100%"
              borderBottom="1px solid white"
              textDecorationColor={"white !important"}
              textUnderlineOffset="3px"
            >
              {isBlickeMenu ? (
                <BlickeMenuItem kategorie={kategorie} item={item} />
              ) : (
                <BereicheMenuItem kategorie={kategorie} />
              )}
            </Link>
          )
        })}
        {isBlickeMenu && (
          <Link
            as={GatsbyLink}
            to={"/blicke-bestellen/"}
            w="100%"
            h="100%"
            _hover={{ textDecoration: "none" }}
            _focus={{
              opacity: 0.8,
              outline: "-webkit-focus-ring-color auto 3px",
            }}
          >
            <BlickeMagazin jahr={currentYear} />
          </Link>
        )}
      </VStack>
    </>
  )
}

const BlickeMagazin = ({ jahr }) => {
  return (
    <HStack
      spacing={0}
      align="stretch"
      justify="space-between"
      w="100%"
      h="100%"
      borderBottom="1px solid white"
    >
      <Center
        minH="5rem"
        h="100%"
        flex={1}
        sx={{ backgroundColor: "dark_blue" }}
      >
        <Text
          textAlign="center"
          as="p"
          color="white"
          fontWeight="bold"
          fontFamily="CocoSharpL-Bold"
        >
          blicke{" "}
          <Text as="span" color="secondary">
            {jahr}
          </Text>{" "}
          <Text
            as="span"
            fontFamily="CocoSharpL-Regular"
            fontWeight={400}
            color="white"
          >
            bestellen
          </Text>
        </Text>
      </Center>
      <Flex w="30%">
        {jahr === 2021 ? (
          <StaticImage
            src={"../../desktop/submenus/blicke-2021.jpg"}
            alt="Blicke Magazin"
            imgStyle={{
              height: "100%",
            }}
            style={{ flexGrow: 1 }}
          />
        ) : (
          <StaticImage
            src={"../../desktop/submenus/blicke-2022.jpg"}
            alt="Blicke Magazin"
            imgStyle={{
              height: "100%",
            }}
            style={{ flexGrow: 1 }}
          />
        )}
      </Flex>
    </HStack>
  )
}

const BlickeMenuItem = ({ kategorie, item }) => {
  return (
    <HStack py={4} justifyContent="space-between" alignItems="center">
      <Text
        color="white"
        textAlign="left"
        wordBreak="break-word"
        fontFamily={"kepler-std"}
        fontSize={"1.4375rem"}
        fontWeight={"semibold"}
        lineHeight={"1.45rem"}
        flex={1}
        dangerouslySetInnerHTML={{ __html: item.title }}
      />

      <HStack flexShrink={0} mr={2} spacing={0}>
        {kategorie &&
          kategorie.map((cat, index) => (
            <Box key={index} w="30px" h="30px">
              {CategorySVGSwitch(cat)}
            </Box>
          ))}
      </HStack>
    </HStack>
  )
}

const BereicheMenuItem = ({ kategorie }) => {
  return (
    <HStack justifyContent="flex-start" alignItems="center" wrap="wrap" py={4}>
      <Box w="50px" h="50px" flex="0 0 auto" flexShrink={0} mr={2}>
        {kategorie[0] && CategorySVGSwitch(kategorie[0])}
      </Box>
      <Box flex={2} alignItems="center">
        <Text
          color="white"
          textAlign="left"
          wordBreak="break-word"
          fontFamily={"CocoSharp-Regular"}
          fontSize={"1.375rem"}
          fontWeight={"normal"}
          lineHeight={"1.6rem"}
        >
          {kategorie[0]}
        </Text>
      </Box>
    </HStack>
  )
}

export default SinglePageMenu
