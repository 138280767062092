import React, { useState, useEffect } from "react"

import { useFlexSearch } from "react-use-flexsearch"

import { Link as GatsbyLink } from "gatsby"

import {
  Box,
  Flex,
  Text,
  Center,
  Button,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Input,
  VStack,
} from "@chakra-ui/react"

import { SearchIcon } from "../../svgs"
import CategorySVGSwitch from "../../../../config/svgs"

const SearchInputDesktop = ({ searchValues }) => {
  const initialFocusRef = React.useRef()

  const [popoverIsOpen, setPopoverIsOpen] = React.useState(false)
  const open = () => setPopoverIsOpen(!popoverIsOpen)
  const close = () => {
    setQuery("")
    setPopoverIsOpen(false)
  }

  const [query, setQuery] = useState("")
  const results = useFlexSearch(query, searchValues.index, searchValues.store)

  const handleChange = event => setQuery(event.target.value)

  useEffect(() => {
    setQuery("")
  }, [])

  return (
    <Popover
      placement="left-start"
      initialFocusRef={initialFocusRef}
      isOpen={popoverIsOpen}
      onClose={close}
    >
      <PopoverTrigger>
        <Button
          display={{ base: "none", md: "flex" }}
          mt={{ base: 0, md: -2 }}
          px={4}
          href={"#"}
          position="relative"
          fontSize="sm"
          onClick={open}
          _hover={{
            opacity: "0.9",
          }}
          bg="none"
        >
          <Text
            pt="4px"
            fontWeight="bold"
            letterSpacing="1px"
            fontFamily="CocoSharpL-Bold"
          >
            SUCHEN
          </Text>
          <SearchIcon />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        w="690px"
        maxW="55vw"
        mr={-2}
        borderRadius={0}
        borderWidth="0px"
      >
        <Input
          placeholder={"SUCHBEGRIFF EINGEBEN"}
          _placeholder={{
            color: "dark_blue",
            textAlign: "center",
          }}
          pt={1}
          size="sm"
          height="40px"
          borderColor="dark_blue"
          color="dark_blue"
          letterSpacing="1px"
          focusBorderColor="dark_blue"
          borderRadius={0}
          _focus={{ borderWidth: "1px", boxShadow: "none" }}
          _hover={{ borderColor: "dark_blue" }}
          ref={initialFocusRef}
          onChange={handleChange}
          value={query}
        />
        {results.length > 0 && (
          <Box
            p={2}
            maxHeight="60vh"
            overflowX="scroll"
            borderColor="dark_blue"
            borderBottom="1px"
            borderRight="1px"
            borderLeft="1px"
          >
            {results.map((node, index) => {
              const t = node.type || ""
              const type = t.toLowerCase() === "blicke" ? "blicke" : t
              return (
                <Link
                  as={GatsbyLink}
                  to={node.uri}
                  onClick={close}
                  key={node.id}
                  _focus={{
                    outline: "-webkit-focus-ring-color auto 1px",
                  }}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    dir="row"
                    borderBottomWidth={!(index === results.length - 1) && "1px"}
                    borderBottomColor="dark_blue"
                  >
                    <Center
                      width="100%"
                      maxW="40px"
                      maxH="55px"
                      flexShrink={0}
                      pl={{ base: 0, lg: "3px" }}
                    >
                      {node.kategorie &&
                        node.kategorie[0] &&
                        CategorySVGSwitch(node.kategorie[0])}
                    </Center>
                    <Box px={2} flex={1} minW={"0px"} flexShrink={1} pt="4px">
                      <Text
                        as="h5"
                        fontSize="1.0625rem"
                        lineHeight="1.3125rem"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                      >
                        {node.title}
                      </Text>
                      <Text
                        fontSize="0.875rem"
                        lineHeight="1.3125rem"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        sx={{
                          "*": {
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          },
                        }}
                        dangerouslySetInnerHTML={{
                          __html: node.excerpt ? node.excerpt : null,
                        }}
                      />
                    </Box>
                    <VStack
                      w="100%"
                      maxW="60px"
                      flexShrink={0}
                      h="55px"
                      borderBottom="1px solid white"
                      align="stretch"
                      spacing={0}
                    >
                      <Center w="100%" flex={1} bgColor="dark_blue">
                        <Text
                          color="white"
                          as="span"
                          fontFamily="CocoSharpL-Bold"
                          fontSize="0.75rem"
                          textDecor="none"
                          pt="4px"
                        >
                          {type}
                        </Text>
                      </Center>
                      <Center w="100%" flex={1} bgColor="primary">
                        <Text
                          color="white"
                          as="span"
                          fontFamily="CocoSharpL-Bold"
                          fontSize="1rem"
                          textDecor="none"
                          pt="4px"
                        >
                          {node.jahr}
                        </Text>
                      </Center>
                    </VStack>
                  </Flex>
                </Link>
              )
            })}
          </Box>
        )}
      </PopoverContent>
    </Popover>
  )
}

export default SearchInputDesktop
