import React from "react"
import {
  Box,
  Text,
  Heading,
  Flex,
  Link,
  HStack,
  SimpleGrid,
} from "@chakra-ui/react"

const Downloads = ({ data }) => {
  const { headline, downloads } = data
  return (
    <>
      <Box
        as="section"
        id="downloads"
        py={14}
        width="100vw"
        top={0}
        left="50%"
        right="50%"
        marginLeft="-50vw"
        marginRight="-50vw"
        position="relative"
        bg="dark_blue"
      >
        <Flex direction="column" maxW="8xl" w="100%" mx="auto" h="100%" px={4}>
          <Heading
            as="h2"
            mb={4}
            textAlign="center"
            color="white"
            fontFamily="CocoSharp-Bold"
            fontSize="1.875rem"
          >
            {headline}
          </Heading>

          <SimpleGrid columns={{ base: 1, sm: 2, lg: 3, xl: 5 }} mt={10}>
            {downloads.map((item, index) => {
              return (
                <Box mb={8} key={`downloads-${index}-${item.datei?.id}`}>
                  <HStack
                    height="100%"
                    justifyContent={{ base: "center", lg: "flex-start" }}
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Link
                      href={encodeURI(item.datei?.localFile?.publicURL)}
                      role="group"
                      _hover={{ textDecoration: "none" }}
                      aria-label={`Downloaden Sie hier die PDF zu "${item.text}"`}
                    >
                      <Flex direction="column" align="center">
                        <Box
                          border="1px solid white"
                          borderRadius="50%"
                          py={3}
                          px={4}
                          flex={1}
                          width="70px"
                          height="70px"
                          mb={2}
                          _hover={{ backgroundColor: "primary" }}
                        >
                          <ArrowDownload />
                        </Box>
                        <Text as="span" color="white">
                          PDF
                        </Text>
                      </Flex>
                    </Link>
                    <Text
                      dangerouslySetInnerHTML={{ __html: item.text }}
                      maxW="200px"
                      color="white"
                      lineHeight="1.3125rem"
                      pb={4}
                      px={2}
                    />
                  </HStack>
                </Box>
              )
            })}
          </SimpleGrid>
        </Flex>
      </Box>
    </>
  )
}

export default Downloads

const ArrowDownload = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="43.84"
    height="51.5"
    viewBox="0 0 43.84 51.5"
    style={{ width: "100%", height: "100%" }}
  >
    <g
      id="Group_1655"
      data-name="Group 1655"
      transform="translate(-139.293 -539)"
    >
      <g
        id="Group_1654"
        data-name="Group 1654"
        transform="translate(599.982 120.141) rotate(90)"
      >
        <line
          id="Linie_79"
          data-name="Linie 79"
          x1="40"
          transform="translate(418.859 438.769)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
        <path
          id="Pfad_1589"
          data-name="Pfad 1589"
          d="M437.646,417.556l21.213,21.213-21.213,21.213"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
      </g>
      <line
        id="Line_80"
        data-name="Line 80"
        x2="41"
        transform="translate(141.5 589.5)"
        fill="none"
        stroke="#fff"
        strokeWidth="2"
      />
    </g>
  </svg>
)
