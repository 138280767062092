import React from "react"
import { Container } from "@chakra-ui/react"

import Navbar from "./Navbar"
import Footer from "./Footer"

const Layout = ({ children, location, pageContext }) => {
  return (
    <>
      <Navbar location={location} pageType={pageContext.type} />
      <Container as="main" maxW={"7xl"}>
        {children}
      </Container>
      <Footer />
    </>
  )
}

export default Layout
