import React from "react"

// Svgs for the different categories are stored as JSX and are called using a switch statement
const CategorySwitch = category => {
  switch (category) {
    case "Wasser":
      return <Wasser />
    case "Energie":
      return <Energie />
    case "Klimaschutz & Klimawandelanpassung":
      return <Klimaschutz />
    case "Konsum & nachhaltige Beschaffung":
      return <Konsum />
    case "Nachhaltigkeit, Vorbild Land":
      return <Nachhaltigkeit />
    case "Nachhaltige Wirtschaft, Umwelttechnologie & Infrastruktur":
      return <Wirtschaft />
    case "Raumordnung & Mobilität":
      return <Raumordnung />
    case "Bildung, Wissenschaft & Arbeit":
      return <Bildung />
    case "Naturschutz & Biodiversität":
      return <Naturschutz />
    case "Menschen im Land":
      return <Landmenschen />
    case "Abfallwirtschaft & Kreislaufwirtschaft":
      return <Abfall />
    case "Landwirtschaft, Forstwirtschaft, Bodenschutz":
      return <Landwirtschaft />
    // old categories from here
    case "Gouvernance & SDG":
      return <Nachhaltigkeit />
    case "Land- & Forstwirtschaft": // old
      return <Landwirtschaft />
    case "Kreislaufwirtschaft": // old
      return <Abfall />
    case "Naturschutz": // old
      return <Naturschutz />
    case "Bildung & Wissenschaft": // old
      return <Bildung />
    case "Mobilität & Raumentwicklung": // old
      return <Raumordnung />
    case "Leben am Land": // old
      return <Landmenschen />
    case "Wirtschaft & Digitalisierung": // old
      return <Wirtschaft />
    case "Nachhaltiger Konsum": // old
      return <Konsum />
    case "Klima": // old
      return <Klimaschutz />
    default:
      return null
  }
}

export default CategorySwitch

export const Abfall = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
  >
    <g id="Group_1900" data-name="Group 1900" transform="translate(-95 -155)">
      <g id="Group_1859" data-name="Group 1859" transform="translate(-10 -270)">
        <path
          id="Path_2351"
          data-name="Path 2351"
          d="M40,0A40,40,0,1,1,0,40,40,40,0,0,1,40,0Z"
          transform="translate(185 425) rotate(90)"
          fill="#7ebcb1"
        />
      </g>
      <path
        id="Path_2408"
        data-name="Path 2408"
        d="M55.585,3.955H42.748a1.919,1.919,0,0,0-1.916,1.914V7.852H85.594V5.869a1.914,1.914,0,0,0-1.911-1.914H70.841V2.816A2.818,2.818,0,0,0,68.028,0H58.4a2.817,2.817,0,0,0-2.814,2.816V3.955Zm24.574,47.5a1.912,1.912,0,0,1-1.906,1.8H48.178a1.917,1.917,0,0,1-1.911-1.8l-2.55-41.8H82.709ZM58.274,27.2l-5.691,1.38L54.4,29.636l-3.581,6.2a1,1,0,0,0,0,1.023l1.688,2.924a1,1,0,0,0,.886.511h1.146a.768.768,0,0,1-.7-1.151l1.8-3.119L58.1,31.771l1.822,1.052Zm3.551,10.958,4.04,4.238V40.3h7.162a1,1,0,0,0,.886-.511L75.6,36.862a1,1,0,0,0,0-1.023l-.573-.992a.768.768,0,0,1-.647,1.181H65.866v-2.1ZM68.6,32.245l1.326,2.3h3.639c.7,0,.678-.592.634-1.139l-1.9-3.293Zm.943-2.636,1.65-5.618-1.822,1.052-3.581-6.2a1,1,0,0,0-.886-.511H61.525a1,1,0,0,0-.886.511l-.573.992a.768.768,0,0,1,1.346-.03l1.8,3.119,2.457,4.256-1.822,1.052Zm-8.51-2.9,1.326-2.3-1.82-3.152c-.35-.607-.852-.291-1.3.021l-1.9,3.294Zm-1.015,9.324H57.361l-1.82,3.152c-.35.607.174.883.669,1.118h3.8Z"
        transform="translate(71.787 168.372)"
        fill="#01214a"
        fillRule="evenodd"
      />
    </g>
  </svg>
)

export const Konsum = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
  >
    <g id="Group_1913" data-name="Group 1913" transform="translate(-906 -155)">
      <g id="Group_1884" data-name="Group 1884" transform="translate(801 -270)">
        <path
          id="Path_2351"
          data-name="Path 2351"
          d="M40,0A40,40,0,1,1,0,40,40,40,0,0,1,40,0Z"
          transform="translate(185 425) rotate(90)"
          fill="#68ae44"
        />
      </g>
      <g
        id="Group_1887"
        data-name="Group 1887"
        transform="translate(893.458 161.794)"
      >
        <g
          id="Group_1886"
          data-name="Group 1886"
          transform="translate(28.843 0)"
        >
          <g id="Group_1885" data-name="Group 1885" transform="translate(0 0)">
            <path
              id="Path_2369"
              data-name="Path 2369"
              d="M102.777,283.485a3.033,3.033,0,0,0,5.88-1.047v-.813l-5.209,1.715A3.438,3.438,0,0,1,102.777,283.485Z"
              transform="translate(-87.078 -221.824)"
              fill="#01214a"
            />
            <circle
              id="Ellipse_84"
              data-name="Ellipse 84"
              cx="5.238"
              cy="5.238"
              r="5.238"
              transform="translate(16.995)"
              fill="#01214a"
            />
            <path
              id="Path_2370"
              data-name="Path 2370"
              d="M37.349,190.022a3.39,3.39,0,0,1,1.125-3.376l-.462-1.4-.479,1.455-.5-.164a1.615,1.615,0,0,0-1.987.894L28.975,201.5a1.615,1.615,0,0,0,.977,2.173l6.216,2.047a1.615,1.615,0,0,0,2.077-1.167l1.243-5.339-2.138-9.182S37.35,190.025,37.349,190.022Z"
              transform="translate(-28.843 -145.908)"
              fill="#01214a"
            />
            <path
              id="Path_2371"
              data-name="Path 2371"
              d="M218.308,192.514,208.146,181.04a1.615,1.615,0,0,0-2.167-.229l-.423.312-.909-1.232v1.477a3.39,3.39,0,0,1,2.123,2.856s0,.006,0,.009l.835,9.39,2.848,4.684a1.615,1.615,0,0,0,2.338.461l5.267-3.885A1.616,1.616,0,0,0,218.308,192.514Z"
              transform="translate(-167.316 -141.693)"
              fill="#01214a"
            />
            <path
              id="Path_2372"
              data-name="Path 2372"
              d="M101.58,87.755a1.615,1.615,0,0,0-1.607-1.472h-.525V84.1a2.527,2.527,0,0,0,1.624-2.657L98.923,62.994A6,6,0,0,0,92.945,57.2h-13.6a6,6,0,0,0-5.981,5.952v.019c0,.732.043,17.886.045,18.569a2.526,2.526,0,0,0,1.436,2.271l.887,2.694-.5.164a1.614,1.614,0,0,0-1.067,1.9L77.645,103.7a1.615,1.615,0,0,0,2.077,1.167l6.216-2.047a1.614,1.614,0,0,0,.977-2.173L80.843,86.574a1.614,1.614,0,0,0-1.987-.894l-.5.164-.733-2.227a2.519,2.519,0,0,0,.843-1.887c0-.7-.043-17.84-.045-18.557,0-.166.551-.3.717-.3a.3.3,0,0,1,.306.3l-.02,18.255h0v1.929l.151.457a3.406,3.406,0,0,1,2.914,2.052l3,6.957V81.425H86.8v14.43l1.761,4.081a3.409,3.409,0,0,1-1.761,4.472v1.262a3.033,3.033,0,0,0,6.051.3,3.407,3.407,0,0,1-1.463-3.113L92.951,86.49s-.231-2.474-.273-23.231a.531.531,0,0,1,.491-.53c.277-.021.662.175.7.451h0c.031.206.864,7.555,2.181,18.849a2.514,2.514,0,0,0,.637,1.4l0,2.851h-.526a1.614,1.614,0,0,0-1.608,1.47l-1.376,15.265a1.614,1.614,0,0,0,1.607,1.759l6.545,0h0a1.615,1.615,0,0,0,1.608-1.757Z"
              transform="translate(-63.913 -45.056)"
              fill="#01214a"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Wasser = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
  >
    <g id="Group_1867" data-name="Group 1867" transform="translate(-95 -155)">
      <g id="Group_1859" data-name="Group 1859" transform="translate(-10 -270)">
        <path
          id="Path_2351"
          data-name="Path 2351"
          d="M40,0A40,40,0,1,1,0,40,40,40,0,0,1,40,0Z"
          transform="translate(185 425) rotate(90)"
          fill="#95c266"
        />
      </g>
      <g
        id="Group_1866"
        data-name="Group 1866"
        transform="translate(105 107.5)"
      >
        <g
          id="Group_1861"
          data-name="Group 1861"
          transform="translate(0 95.927)"
        >
          <g id="Group_1860" data-name="Group 1860">
            <path
              id="Path_2352"
              data-name="Path 2352"
              d="M59.678,186.512a11.65,11.65,0,0,1-7.352-2.429,7.376,7.376,0,0,0-9.115,0,12.341,12.341,0,0,1-14.7,0,6.952,6.952,0,0,0-4.557-1.576,6.951,6.951,0,0,0-4.557,1.576,11.8,11.8,0,0,1-7.352,2.429,12.021,12.021,0,0,1-7.418-2.429A7.164,7.164,0,0,0,0,182.507V177.5a11.867,11.867,0,0,1,7.418,2.429,7.047,7.047,0,0,0,4.59,1.576,7,7,0,0,0,4.574-1.576,11.828,11.828,0,0,1,7.36-2.429,11.815,11.815,0,0,1,7.356,2.429,7.38,7.38,0,0,0,9.118,0,12.343,12.343,0,0,1,14.7,0,6.975,6.975,0,0,0,4.558,1.576Z"
              transform="translate(0 -177.5)"
              fill="#002149"
            />
          </g>
        </g>
        <g
          id="Group_1863"
          data-name="Group 1863"
          transform="translate(0 85.713)"
        >
          <g id="Group_1862" data-name="Group 1862">
            <path
              id="Path_2353"
              data-name="Path 2353"
              d="M59.678,135.512a11.65,11.65,0,0,1-7.352-2.429,7.376,7.376,0,0,0-9.115,0,12.341,12.341,0,0,1-14.7,0,6.952,6.952,0,0,0-4.557-1.576,6.951,6.951,0,0,0-4.557,1.576,11.8,11.8,0,0,1-7.352,2.429,12.021,12.021,0,0,1-7.418-2.429A7.164,7.164,0,0,0,0,131.507V126.5a11.867,11.867,0,0,1,7.418,2.429,7.047,7.047,0,0,0,4.59,1.576,7,7,0,0,0,4.574-1.576,11.828,11.828,0,0,1,7.36-2.429,11.815,11.815,0,0,1,7.356,2.429,7.38,7.38,0,0,0,9.118,0,12.343,12.343,0,0,1,14.7,0,6.975,6.975,0,0,0,4.558,1.576Z"
              transform="translate(0 -126.5)"
              fill="#002149"
            />
          </g>
        </g>
        <g id="Group_1865" data-name="Group 1865" transform="translate(0 75.5)">
          <g id="Group_1864" data-name="Group 1864">
            <path
              id="Path_2354"
              data-name="Path 2354"
              d="M59.678,84.512a11.65,11.65,0,0,1-7.352-2.429,7.376,7.376,0,0,0-9.115,0,12.341,12.341,0,0,1-14.7,0,6.952,6.952,0,0,0-4.557-1.576,6.951,6.951,0,0,0-4.557,1.576,11.8,11.8,0,0,1-7.352,2.429,12.022,12.022,0,0,1-7.418-2.429A7.164,7.164,0,0,0,0,80.507V75.5a11.867,11.867,0,0,1,7.418,2.429,7.047,7.047,0,0,0,4.59,1.576,7,7,0,0,0,4.574-1.576,11.828,11.828,0,0,1,7.36-2.429A11.815,11.815,0,0,1,31.3,77.929a7.38,7.38,0,0,0,9.118,0,12.343,12.343,0,0,1,14.7,0,6.975,6.975,0,0,0,4.558,1.576Z"
              transform="translate(0 -75.5)"
              fill="#002149"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Raumordnung = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
  >
    <g id="Group_1888" data-name="Group 1888" transform="translate(-95 -155)">
      <g id="Group_1859" data-name="Group 1859" transform="translate(-10 -270)">
        <path
          id="Path_2351"
          data-name="Path 2351"
          d="M40,0A40,40,0,1,1,0,40,40,40,0,0,1,40,0Z"
          transform="translate(185 425) rotate(90)"
          fill="#bcd233"
        />
      </g>
      <g
        id="Group_1890"
        data-name="Group 1890"
        transform="translate(85.117 165.246)"
      >
        <g id="Group_1889" data-name="Group 1889" transform="translate(23.026)">
          <path
            id="Path_2373"
            data-name="Path 2373"
            d="M76.742,46.658,65.226,42.9,51.1,0h-2.43L34.623,42.91a2.422,2.422,0,0,0,.943,1.2c.416.274,4.33,2.683,13.3,2.683a42.2,42.2,0,0,0,5.449-.333l.194,1.339a43.033,43.033,0,0,1-5.643.352c-9.366,0-13.6-2.61-14.047-2.907a3.783,3.783,0,0,1-1.487-1.916l-10.3,3.331,26.858,8.681ZM47.3,11.07a7.2,7.2,0,0,0,5.139.022l1.975,6a13.6,13.6,0,0,1-9.077-.016Zm2.6,17.749h0A21.177,21.177,0,0,1,42,27.3l1.967-6.022a14.978,14.978,0,0,0,11.829.016l1.978,6.016A21.187,21.187,0,0,1,49.9,28.818Z"
            transform="translate(-23.027)"
            fill="#01214a"
          />
          <path
            id="Path_2374"
            data-name="Path 2374"
            d="M23.026,388.222v1.963l26.858,8.646L76.737,390.1v-1.955l-26.854,8.72Z"
            transform="translate(-23.026 -339.322)"
            fill="#01214a"
          />
        </g>
      </g>
    </g>
  </svg>
)

export const Wirtschaft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
  >
    <g id="Group_1899" data-name="Group 1899" transform="translate(-95 -155)">
      <g id="Group_1859" data-name="Group 1859" transform="translate(-10 -270)">
        <path
          id="Path_2351"
          data-name="Path 2351"
          d="M40,0A40,40,0,1,1,0,40,40,40,0,0,1,40,0Z"
          transform="translate(185 425) rotate(90)"
          fill="#f2dd20"
        />
      </g>
      <g
        id="Group_1903"
        data-name="Group 1903"
        transform="translate(-1.933 163.126)"
      >
        <path
          id="Path_2406"
          data-name="Path 2406"
          d="M148.42,383.945,150.384,370h-26.9l1.964,13.945H120v3.735h33.866v-3.735Z"
          transform="translate(0 -323.932)"
          fill="#01214a"
        />
        <path
          id="Path_2407"
          data-name="Path 2407"
          d="M149.073,29.765V42.333H160.08a13.033,13.033,0,0,0,3.934-9.338V31.127h-1.868a13.033,13.033,0,0,0-9.338,3.934v-5.3a14.941,14.941,0,1,0-3.735,0Zm-2.366-11.836h2.366V13.218a6.067,6.067,0,0,1-2.366.478V9.961a2.368,2.368,0,0,0,2.366-2.366V7.47h3.735V17.929h2.366v3.735h-8.467Z"
          transform="translate(-14.008)"
          fill="#01214a"
        />
      </g>
    </g>
  </svg>
)

export const Energie = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
  >
    <g id="Group_1910" data-name="Group 1910" transform="translate(-361 -155)">
      <g id="Group_1868" data-name="Group 1868" transform="translate(256 -270)">
        <path
          id="Path_2351"
          data-name="Path 2351"
          d="M40,0A40,40,0,1,1,0,40,40,40,0,0,1,40,0Z"
          transform="translate(185 425) rotate(90)"
          fill="#e9c542"
        />
      </g>
      <g
        id="Group_1869"
        data-name="Group 1869"
        transform="translate(373.189 167.189)"
      >
        <g
          id="Group_1871"
          data-name="Group 1871"
          transform="translate(16.894 10.115)"
        >
          <g id="Group_1870" data-name="Group 1870">
            <path
              id="Path_2355"
              data-name="Path 2355"
              d="M177.345,105.916h-9.287V93.109l-12.546,22.584H164.8V128.5Z"
              transform="translate(-155.512 -93.109)"
              fill="#01214a"
            />
          </g>
        </g>
        <g
          id="Group_1873"
          data-name="Group 1873"
          transform="translate(0 13.245)"
        >
          <g id="Group_1872" data-name="Group 1872">
            <path
              id="Path_2356"
              data-name="Path 2356"
              d="M13.24,156.014l-.614-10.048-2.268,2.309c-1.95-3.26-3.84-6.393-3.84-11.816a19.279,19.279,0,0,1,2.744-9.882l-4.978.976.234-5.632A26.021,26.021,0,0,0,0,136.46c0,6.395,2.012,10.987,5.31,15.84l-2.355,1.949Z"
              transform="translate(0 -121.921)"
              fill="#01214a"
            />
          </g>
        </g>
        <g id="Group_1875" data-name="Group 1875" transform="translate(7.82)">
          <g id="Group_1874" data-name="Group 1874">
            <path
              id="Path_2357"
              data-name="Path 2357"
              d="M105.388,3.775A25.765,25.765,0,0,0,91.976,0c-6.86,0-12.084,2.414-17.1,6.351L72.422,4.289,71.986,14.8l10.7-2.1-2.825-2.152c3.26-1.974,6.378-3.988,12.1-3.988a19.654,19.654,0,0,1,8.412,1.9L99.3,3.775Z"
              transform="translate(-71.986 0)"
              fill="#01214a"
            />
          </g>
        </g>
        <g
          id="Group_1877"
          data-name="Group 1877"
          transform="translate(39.326 7.116)"
        >
          <g id="Group_1876" data-name="Group 1876">
            <path
              id="Path_2358"
              data-name="Path 2358"
              d="M370.867,67.87l2.771-2.37H362l2.32,10.259,2.63-2.578c2.361,3.259,4.827,6.841,4.827,12.987a19.472,19.472,0,0,1-1.518,7.512l3.144-1.169.642,7.887a25.771,25.771,0,0,0,4.25-14.23C378.3,78.589,375.46,73.17,370.867,67.87Z"
              transform="translate(-362 -65.5)"
              fill="#01214a"
            />
          </g>
        </g>
        <g
          id="Group_1879"
          data-name="Group 1879"
          transform="translate(12.48 38.817)"
        >
          <g id="Group_1878" data-name="Group 1878">
            <path
              id="Path_2359"
              data-name="Path 2359"
              d="M150.122,357.312l-10.134,3.768,2.974,1.984c-3.259,2.128-6.53,4.429-12.669,4.429a19.745,19.745,0,0,1-11.406-3.589l.365,5.972-4.373-.75a25.907,25.907,0,0,0,15.33,4.99c7.522,0,13.113-2.859,18.238-7.4l2.589,1.846Z"
              transform="translate(-114.881 -357.312)"
              fill="#01214a"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Klimaschutz = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
  >
    <g id="Group_1911" data-name="Group 1911" transform="translate(-635 -155)">
      <g id="Group_1880" data-name="Group 1880" transform="translate(530 -270)">
        <path
          id="Path_2351"
          data-name="Path 2351"
          d="M40,0A40,40,0,1,1,0,40,40,40,0,0,1,40,0Z"
          transform="translate(185 425) rotate(90)"
          fill="#db9e27"
        />
      </g>
      <g
        id="Group_1883"
        data-name="Group 1883"
        transform="translate(645.228 166.56)"
      >
        <path
          id="Path_2360"
          data-name="Path 2360"
          d="M.938,60.25H7.749v3.391H.938Z"
          transform="translate(0 -53.439)"
          fill="#01214a"
        />
        <path
          id="Path_2361"
          data-name="Path 2361"
          d="M.938,180.75H7.749v3.391H.938Z"
          transform="translate(0 -160.317)"
          fill="#01214a"
        />
        <path
          id="Path_2362"
          data-name="Path 2362"
          d="M.938,301.25H7.749v3.391H.938Z"
          transform="translate(0 -267.194)"
          fill="#01214a"
        />
        <path
          id="Path_2363"
          data-name="Path 2363"
          d="M31.063,120.5h3.406v3.391H31.063Z"
          transform="translate(-26.719 -106.878)"
          fill="#01214a"
        />
        <path
          id="Path_2364"
          data-name="Path 2364"
          d="M31.063,241h3.406v3.391H31.063Z"
          transform="translate(-26.719 -213.755)"
          fill="#01214a"
        />
        <g
          id="Group_1881"
          data-name="Group 1881"
          transform="translate(30.295 7.235)"
        >
          <path
            id="Path_2365"
            data-name="Path 2365"
            d="M265.532,96.558a5.319,5.319,0,0,1,5.313-5.313,1.924,1.924,0,0,0,1.922-1.922,5.319,5.319,0,0,1,5.313-5.313h10.851c0-.038,0-.076,0-.114a19.9,19.9,0,0,0-19.9-19.9l-.114,0v3.616a1.924,1.924,0,0,0,1.922,1.922,5.313,5.313,0,1,1,0,10.627H267.3a1.924,1.924,0,0,0-1.922,1.922,5.32,5.32,0,0,1-4.755,5.284v6.938a14.268,14.268,0,0,1,3.6,8.895q.645.16,1.306.277V96.558Z"
            transform="translate(-260.621 -64)"
            fill="#01214a"
          />
          <path
            id="Path_2366"
            data-name="Path 2366"
            d="M261.986,84.519a5.319,5.319,0,0,1,5.313-5.313h3.547a1.922,1.922,0,1,0,0-3.844,5.319,5.319,0,0,1-5.313-5.313V66.741a19.765,19.765,0,0,0-4.911,1.554V86.357A1.925,1.925,0,0,0,261.986,84.519Z"
            transform="translate(-260.622 -66.431)"
            fill="#01214a"
          />
          <path
            id="Path_2367"
            data-name="Path 2367"
            d="M343.219,271a1.924,1.924,0,0,0-1.922,1.922,5.319,5.319,0,0,1-5.313,5.313,1.924,1.924,0,0,0-1.922,1.922v7.232l.114,0A19.9,19.9,0,0,0,353.764,271H343.219Z"
            transform="translate(-325.76 -247.599)"
            fill="#01214a"
          />
        </g>
        <g
          id="Group_1882"
          data-name="Group 1882"
          transform="translate(8.816 0)"
        >
          <path
            id="Path_2368"
            data-name="Path 2368"
            d="M88.716,38.939V7.235a7.235,7.235,0,1,0-14.47,0v31.7a10.853,10.853,0,0,0,7.081,18.94h.159a10.852,10.852,0,0,0,7.229-18.941ZM81.482,52.127a5.1,5.1,0,0,1-1.7-9.912V6.8h3.391V42.216a5.1,5.1,0,0,1-1.7,9.912Z"
            transform="translate(-70.63 0)"
            fill="#01214a"
          />
          <circle
            id="Ellipse_83"
            data-name="Ellipse 83"
            cx="1.71"
            cy="1.71"
            r="1.71"
            transform="translate(9.142 45.316)"
            fill="#01214a"
          />
        </g>
      </g>
    </g>
  </svg>
)

export const Landwirtschaft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
  >
    <g id="Group_1918" data-name="Group 1918" transform="translate(-361 -345)">
      <g id="Group_1891" data-name="Group 1891" transform="translate(256 -80)">
        <path
          id="Path_2351"
          data-name="Path 2351"
          d="M40,0A40,40,0,1,1,0,40,40,40,0,0,1,40,0Z"
          transform="translate(185 425) rotate(90)"
          fill="#c78137"
        />
      </g>
      <g
        id="Group_1895"
        data-name="Group 1895"
        transform="translate(377.208 361.149)"
      >
        <path
          id="Path_2375"
          data-name="Path 2375"
          d="M22.068,15.49V7.857h2.787V15.49a6.2,6.2,0,0,0,4.8-6.034v-3.2a6.192,6.192,0,0,0-12.385,0v3.2A6.2,6.2,0,0,0,22.068,15.49Z"
          transform="translate(-15.664)"
          fill="#01214a"
        />
        <path
          id="Path_2376"
          data-name="Path 2376"
          d="M366.273,15.49V7.857h2.788V15.49a6.2,6.2,0,0,0,4.8-6.034v-3.2a6.192,6.192,0,0,0-12.385,0v3.2A6.2,6.2,0,0,0,366.273,15.49Z"
          transform="translate(-327.88)"
          fill="#01214a"
        />
        <path
          id="Path_2377"
          data-name="Path 2377"
          d="M194.175,15.49v-8.8h2.787v8.8a6.2,6.2,0,0,0,4.8-6.034v-3.2a6.192,6.192,0,1,0-12.384,0v3.2a6.2,6.2,0,0,0,4.8,6.034Z"
          transform="translate(-171.777)"
          fill="#01214a"
        />
        <path
          id="Path_2378"
          data-name="Path 2378"
          d="M0,262.9v4.981a30.137,30.137,0,0,1,7.164,18.163h8A27.261,27.261,0,0,0,0,262.9Z"
          transform="translate(0 -238.407)"
          fill="#01214a"
        />
        <path
          id="Path_2379"
          data-name="Path 2379"
          d="M239.653,233.062a30.259,30.259,0,0,1,17.272,25.918h8a27.431,27.431,0,0,0-25.276-25.918Z"
          transform="translate(-217.38 -211.343)"
          fill="#01214a"
        />
        <path
          id="Path_2380"
          data-name="Path 2380"
          d="M0,380.094H4.372A27.357,27.357,0,0,0,0,366.585Z"
          transform="translate(0 -332.456)"
          fill="#01214a"
        />
        <path
          id="Path_2381"
          data-name="Path 2381"
          d="M68.906,166.034V169.4h2.787v-3.364a6.227,6.227,0,0,1-2.787,0Z"
          transform="translate(-62.502 -150.544)"
          fill="#01214a"
        />
        <path
          id="Path_2382"
          data-name="Path 2382"
          d="M402.152,235.136a30.522,30.522,0,0,1,4.158,3.478,30.191,30.191,0,0,1,6.051,8.637V232.6A27.41,27.41,0,0,0,402.152,235.136Z"
          transform="translate(-364.777 -210.922)"
          fill="#01214a"
        />
        <path
          id="Path_2383"
          data-name="Path 2383"
          d="M241,166.034v3.443q1.408.1,2.787.331v-3.773a6.227,6.227,0,0,1-2.787,0Z"
          transform="translate(-218.606 -150.544)"
          fill="#01214a"
        />
        <path
          id="Path_2384"
          data-name="Path 2384"
          d="M7.126,233.045a30.159,30.159,0,0,1,12.044,10.25,29.983,29.983,0,0,1,5.251,15.67h8a27.431,27.431,0,0,0-25.3-25.919Z"
          transform="translate(-6.464 -211.327)"
          fill="#01214a"
        />
        <path
          id="Path_2385"
          data-name="Path 2385"
          d="M413.1,166.034V171.2l.06.091q1.342-.5,2.727-.868v-4.386a6.227,6.227,0,0,1-2.788,0Z"
          transform="translate(-374.709 -150.544)"
          fill="#01214a"
        />
        <path
          id="Path_2386"
          data-name="Path 2386"
          d="M123.508,233.06a30.259,30.259,0,0,1,17.273,25.918h8a27.431,27.431,0,0,0-25.277-25.918Z"
          transform="translate(-112.029 -211.341)"
          fill="#01214a"
        />
      </g>
    </g>
  </svg>
)

export const Landmenschen = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
  >
    <g id="Group_1905" data-name="Group 1905" transform="translate(-906 -325)">
      <g id="Group_1896" data-name="Group 1896" transform="translate(801 -100)">
        <path
          id="Path_2351"
          data-name="Path 2351"
          d="M40,0A40,40,0,1,1,0,40,40,40,0,0,1,40,0Z"
          transform="translate(185 425) rotate(90)"
          fill="#c73f32"
        />
      </g>
      <g
        id="Group_1897"
        data-name="Group 1897"
        transform="translate(919.671 338)"
      >
        <path
          id="Path_2398"
          data-name="Path 2398"
          d="M344.029,4.635A6.074,6.074,0,0,0,338.135,0h-.122a6.074,6.074,0,0,0-5.894,4.635,4.965,4.965,0,0,0,1.231,9.774H342.8a4.965,4.965,0,0,0,1.231-9.774Z"
          transform="translate(-293.735)"
          fill="#01214a"
        />
        <path
          id="Path_2399"
          data-name="Path 2399"
          d="M51.924,405.6c-9.434-3.321-20.385-4.827-30.129-5.509q-1.56-.11-3.076-.191c-6.831-.374-12.882-.375-17.121-.375H0v11.862H54.027v-5.054Z"
          transform="translate(0 -357.364)"
          fill="#01214a"
        />
        <path
          id="Path_2400"
          data-name="Path 2400"
          d="M142.7,217.452V223.8c4.806.26,10.007.7,15.261,1.455v-7.806h4.093L150.332,206.2l-11.723,11.253Z"
          transform="translate(-123.983 -184.44)"
          fill="#01214a"
        />
        <path
          id="Path_2401"
          data-name="Path 2401"
          d="M25.2,117.548v-9.92h3.166v9.92a5.9,5.9,0,0,0,5.679-5.886v-8.067A5.9,5.9,0,0,0,28.149,97.7h-2.74a5.9,5.9,0,0,0-5.892,5.892v8.067A5.9,5.9,0,0,0,25.2,117.548Z"
          transform="translate(-17.457 -87.393)"
          fill="#01214a"
        />
        <path
          id="Path_2402"
          data-name="Path 2402"
          d="M73.34,294.651q1.518.018,3.166.052v-8.915H73.34Z"
          transform="translate(-65.601 -255.632)"
          fill="#01214a"
        />
      </g>
    </g>
  </svg>
)

export const Bildung = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
  >
    <g id="Group_1898" data-name="Group 1898" transform="translate(-95 -155)">
      <g id="Group_1859" data-name="Group 1859" transform="translate(-10 -270)">
        <path
          id="Path_2351"
          data-name="Path 2351"
          d="M40,0A40,40,0,1,1,0,40,40,40,0,0,1,40,0Z"
          transform="translate(185 425) rotate(90)"
          fill="#cd5b77"
        />
      </g>
      <g
        id="Group_1902"
        data-name="Group 1902"
        transform="translate(106.979 166.978)"
      >
        <path
          id="Path_2403"
          data-name="Path 2403"
          d="M299.876,22.2c-3.15-3.153.483-10.081,5.7-15.3s12.148-8.85,15.3-5.7-.483,10.081-5.7,15.3S303.027,25.349,299.876,22.2Zm8.176-12.823c-5.21,5.212-6.373,9.672-5.7,10.347s5.137-.49,10.347-5.7,6.373-9.672,5.7-10.347c-1.59-1.574-8.682,4.033-10.347,5.7Z"
          transform="translate(-266.007 -0.022)"
          fill="#01214a"
        />
        <path
          id="Path_2404"
          data-name="Path 2404"
          d="M305.563,16.51c-5.217-5.217-8.85-12.148-5.7-15.3s10.081.483,15.3,5.7,8.85,12.148,5.7,15.3C317.242,25.829,309.5,20.447,305.563,16.51ZM302.341,3.685c-.673.675.488,5.135,5.7,10.347s9.677,6.368,10.347,5.7-.488-5.135-5.7-10.347-9.668-6.37-10.347-5.7Z"
          transform="translate(-265.996 -0.032)"
          fill="#01214a"
        />
        <path
          id="Path_2405"
          data-name="Path 2405"
          d="M44.369,25.9c-4.708,2.473-10.06,3.321-13.8-.42-3.269-3.269-3.269-8.313-.42-13.8-2.288-4.39-2.732-8.5-1.121-11.653C28.7,0,28.349,0,28.022,0A21.049,21.049,0,0,0,7.006,21.017a7.588,7.588,0,0,1-1.191,4.11L.374,33.79A2.144,2.144,0,0,0,0,35.028a2.312,2.312,0,0,0,2.335,2.335h4.67v7.006a4.684,4.684,0,0,0,4.67,4.67,4.166,4.166,0,0,0,1.378-.21L18.682,47.1v7.776a1.156,1.156,0,0,0,1.168,1.168H40.866a1.156,1.156,0,0,0,1.168-1.168V43.131A17.432,17.432,0,0,1,45.209,33.09a20.478,20.478,0,0,0,2.826-5.675A20.921,20.921,0,0,1,44.369,25.9Z"
          transform="translate(0)"
          fill="#01214a"
        />
      </g>
    </g>
  </svg>
)

export const Naturschutz = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
  >
    <g id="Group_1920" data-name="Group 1920" transform="translate(-635 -345)">
      <path
        id="Path_2351"
        data-name="Path 2351"
        d="M40,0A40,40,0,1,1,0,40,40,40,0,0,1,40,0Z"
        transform="translate(715 345) rotate(90)"
        fill="#bc559f"
      />
      <g
        id="Plant-Tree-Ecology-Environment-Forest"
        transform="translate(621.311 311.323)"
      >
        <path
          id="Path_2387"
          data-name="Path 2387"
          d="M24,152h8.19v2.048H24Z"
          transform="translate(0 -92.295)"
          fill="#01214a"
        />
        <path
          id="Path_2388"
          data-name="Path 2388"
          d="M43.574,59.061l1.136-1.7,6.142,4.1-1.136,1.7Z"
          transform="translate(-17.069 -9.764)"
          fill="#01214a"
        />
        <path
          id="Path_2389"
          data-name="Path 2389"
          d="M43.63,221.4l6.142-4.1,1.136,1.7-6.142,4.1Z"
          transform="translate(-17.118 -149.244)"
          fill="#01214a"
        />
        <path
          id="Path_2390"
          data-name="Path 2390"
          d="M424,152h8.19v2.048H424Z"
          transform="translate(-348.811 -92.295)"
          fill="#01214a"
        />
        <path
          id="Path_2391"
          data-name="Path 2391"
          d="M411.558,61.3l6.142-4.1,1.136,1.7L412.694,63Z"
          transform="translate(-337.962 -9.629)"
          fill="#01214a"
        />
        <path
          id="Path_2392"
          data-name="Path 2392"
          d="M411.678,219.133l1.136-1.7,6.142,4.1-1.136,1.7Z"
          transform="translate(-338.066 -149.351)"
          fill="#01214a"
        />
        <path
          id="Path_2393"
          data-name="Path 2393"
          d="M209.322,188.01,197.7,176.38h-.6V160H195.05v16.38h-.6l-7.166,7.166a11.178,11.178,0,0,1,4.167.809l.532.215v-2.048h2.048v2.048h-2.037l2.559,1.024h6.972a4.1,4.1,0,0,1,4.1,4.1,4.008,4.008,0,0,1-.051.491Zm-13.248-7.535h-2.048v-2.048h2.048Zm4.1,2.048h-2.048v-2.048h2.048Z"
          transform="translate(-142.385 -99.272)"
          fill="#01214a"
        />
        <path
          id="Path_2394"
          data-name="Path 2394"
          d="M110.817,361.945a2.064,2.064,0,0,0-1.239-.952,2.016,2.016,0,0,0-1.556.2L97.1,367.5a5.128,5.128,0,0,1-2.559.686H83.452v-2.047H94.017a2.048,2.048,0,0,0,0-4.1H86.851a.923.923,0,0,1-.379-.072l-3.3-1.321A9.275,9.275,0,0,0,79.756,360H78.425a9.183,9.183,0,0,0-4.74,1.31l-2.693,1.618a1.023,1.023,0,0,1-.522.143H64v11.261l9.429-1.011a.833.833,0,0,1,.225,0l13.084,1.454a15.265,15.265,0,0,0,9.368-1.966l13.964-8.069A2.048,2.048,0,0,0,110.817,361.945Z"
          transform="translate(-34.881 -273.677)"
          fill="#01214a"
        />
        <path
          id="Path_2395"
          data-name="Path 2395"
          d="M237.179,60.534a32.351,32.351,0,0,1,.983-7.3l1.945.655a31.131,31.131,0,0,0-.9,7.013l2.068-2.467a9.214,9.214,0,0,0-.389-12.275l-3.245,3.87a9.214,9.214,0,0,0-1.577,9.132,12.068,12.068,0,0,1,1.116,1.372Z"
          transform="translate(-184.421)"
          fill="#01214a"
        />
        <path
          id="Path_2396"
          data-name="Path 2396"
          d="M145.633,144.19h3.491c-1.792-1.515-4.515-3.552-6.808-4.126l.491-1.986c2.652.665,5.61,2.836,7.555,4.474A9.233,9.233,0,0,0,141.537,136H136.48a9.222,9.222,0,0,0,9.153,8.19Z"
          transform="translate(-98.086 -78.343)"
          fill="#01214a"
        />
        <path
          id="Path_2397"
          data-name="Path 2397"
          d="M267.04,166.552c1.945-1.638,4.9-3.808,7.555-4.474l.491,1.986c-2.293.573-5.017,2.611-6.808,4.126h3.491a9.222,9.222,0,0,0,9.153-8.19h-5.057A9.233,9.233,0,0,0,267.04,166.552Z"
          transform="translate(-211.938 -99.272)"
          fill="#01214a"
        />
      </g>
    </g>
  </svg>
)

export const Nachhaltigkeit = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
  >
    <g id="Group_1901" data-name="Group 1901" transform="translate(-95 -155)">
      <g id="Group_1859" data-name="Group 1859" transform="translate(-10 -270)">
        <path
          id="Path_2351"
          data-name="Path 2351"
          d="M40,0A40,40,0,1,1,0,40,40,40,0,0,1,40,0Z"
          transform="translate(185 425) rotate(90)"
          fill="#1a8ed3"
        />
      </g>
      <g
        id="Group_1904"
        data-name="Group 1904"
        transform="translate(100.127 165.384)"
      >
        <path
          id="Path_2409"
          data-name="Path 2409"
          d="M368.329,59.012A3.012,3.012,0,1,1,365.317,56,3.012,3.012,0,0,1,368.329,59.012Z"
          transform="translate(-311.592 -48.973)"
          fill="#01214a"
        />
        <path
          id="Path_2410"
          data-name="Path 2410"
          d="M378.3,0h2.008V2.008H378.3Z"
          transform="translate(-325.584)"
          fill="#01214a"
        />
        <path
          id="Path_2411"
          data-name="Path 2411"
          d="M324.074,19.193l1.42-1.42,1.42,1.42-1.42,1.42Z"
          transform="translate(-278.159 -15.543)"
          fill="#01214a"
        />
        <path
          id="Path_2412"
          data-name="Path 2412"
          d="M306.3,72h2.008v2.008H306.3Z"
          transform="translate(-262.62 -62.965)"
          fill="#01214a"
        />
        <path
          id="Path_2413"
          data-name="Path 2413"
          d="M324.078,121.018l1.42-1.42,1.42,1.42-1.42,1.42Z"
          transform="translate(-278.162 -104.59)"
          fill="#01214a"
        />
        <path
          id="Path_2414"
          data-name="Path 2414"
          d="M378.3,144h2.008v2.008H378.3Z"
          transform="translate(-325.584 -125.929)"
          fill="#01214a"
        />
        <path
          id="Path_2415"
          data-name="Path 2415"
          d="M425.9,121.021l1.42-1.419,1.42,1.42-1.42,1.42Z"
          transform="translate(-367.208 -104.593)"
          fill="#01214a"
        />
        <path
          id="Path_2416"
          data-name="Path 2416"
          d="M450.3,72h2.008v2.008H450.3Z"
          transform="translate(-388.549 -62.965)"
          fill="#01214a"
        />
        <path
          id="Path_2417"
          data-name="Path 2417"
          d="M425.9,19.2l1.42-1.42,1.42,1.42-1.42,1.42Z"
          transform="translate(-367.208 -15.55)"
          fill="#01214a"
        />
        <path
          id="Path_2418"
          data-name="Path 2418"
          d="M157.5,240H154.3v3.012H158.1Z"
          transform="translate(-129.694 -209.882)"
          fill="#01214a"
        />
        <path
          id="Path_2419"
          data-name="Path 2419"
          d="M53.169,283.012v0H57l.6-3.014H54.14Z"
          transform="translate(-41.249 -244.863)"
          fill="#01214a"
        />
        <path
          id="Path_2420"
          data-name="Path 2420"
          d="M66.063,243.012h3.222l.6-3.012H67.034Z"
          transform="translate(-52.526 -209.882)"
          fill="#01214a"
        />
        <path
          id="Path_2421"
          data-name="Path 2421"
          d="M56.423,366.055V352H26.305v14.055H36.344V357.02a1,1,0,0,1,1-1h8.031a1,1,0,0,1,1,1v9.035Zm-22.086-5.02a1,1,0,0,1-1,1H29.317a1,1,0,0,1-1-1V357.02a1,1,0,0,1,1-1h4.016a1,1,0,0,1,1,1Zm14.055-4.016a1,1,0,0,1,1-1h4.016a1,1,0,0,1,1,1v4.016a1,1,0,0,1-1,1H49.4a1,1,0,0,1-1-1Z"
          transform="translate(-17.757 -307.828)"
          fill="#01214a"
        />
        <path
          id="Path_2422"
          data-name="Path 2422"
          d="M218.3,400h2.008v2.008H218.3Z"
          transform="translate(-185.663 -349.804)"
          fill="#01214a"
        />
        <path
          id="Path_2423"
          data-name="Path 2423"
          d="M58.305,400h2.008v2.008H58.305Z"
          transform="translate(-45.741 -349.804)"
          fill="#01214a"
        />
        <path
          id="Path_2424"
          data-name="Path 2424"
          d="M122.3,400h6.024v8.031H122.3Z"
          transform="translate(-101.71 -349.804)"
          fill="#01214a"
        />
        <path
          id="Path_2425"
          data-name="Path 2425"
          d="M198.917,240h-2.855l.6,3.012h3.223Z"
          transform="translate(-166.211 -209.882)"
          fill="#01214a"
        />
        <path
          id="Path_2426"
          data-name="Path 2426"
          d="M204.062,280l.6,3.012H208.5L207.53,280Z"
          transform="translate(-173.207 -244.863)"
          fill="#01214a"
        />
        <path
          id="Path_2427"
          data-name="Path 2427"
          d="M100.055,283.014h4.8V280h-4.2Z"
          transform="translate(-82.252 -244.863)"
          fill="#01214a"
        />
        <path
          id="Path_2428"
          data-name="Path 2428"
          d="M34.967,208H12.247L6,224.063H41.214Zm.327,14.055H11.92a2.008,2.008,0,0,1-1.907-2.623L12.6,211.4a2.007,2.007,0,0,1,1.908-1.393h18.2a2.008,2.008,0,0,1,1.907,1.392l2.588,8.031a2.008,2.008,0,0,1-1.907,2.624Z"
          transform="translate(0 -181.898)"
          fill="#01214a"
        />
        <path
          id="Path_2429"
          data-name="Path 2429"
          d="M154.3,280v3.013h4.8L158.5,280Z"
          transform="translate(-129.694 -244.863)"
          fill="#01214a"
        />
        <path
          id="Path_2430"
          data-name="Path 2430"
          d="M108.062,243.012h3.8V240h-3.193Z"
          transform="translate(-89.254 -209.882)"
          fill="#01214a"
        />
        <path
          id="Path_2431"
          data-name="Path 2431"
          d="M61.348,67.044a1.986,1.986,0,0,0-1.445.622,1,1,0,0,1-1.679-1,2,2,0,1,0-3.9-.622c0,.025.016.1.017.128a1,1,0,0,1-1.344,1,1.983,1.983,0,0,0-.68-.128,2.008,2.008,0,0,0,0,4.016h9.035a2.008,2.008,0,0,0,0-4.016Z"
          transform="translate(-38.745 -56.001)"
          fill="#01214a"
        />
        <path
          id="Path_2432"
          data-name="Path 2432"
          d="M382.865,400h-3.247l-.251,2.008h3.749Z"
          transform="translate(-326.513 -349.804)"
          fill="#01214a"
        />
        <path
          id="Path_2433"
          data-name="Path 2433"
          d="M385.861,368h-2.243l-.251,2.008h2.745Z"
          transform="translate(-330.011 -321.82)"
          fill="#01214a"
        />
        <path
          id="Path_2434"
          data-name="Path 2434"
          d="M353.848,370.008h1.68l.251-2.008H354.6Z"
          transform="translate(-304.197 -321.82)"
          fill="#01214a"
        />
        <path
          id="Path_2435"
          data-name="Path 2435"
          d="M341.848,402.008h2.684l.251-2.008H342.6Z"
          transform="translate(-293.702 -349.804)"
          fill="#01214a"
        />
        <path
          id="Path_2436"
          data-name="Path 2436"
          d="M374.321,432h-2.008v4.016H370.3v1h6.023v-1h-2.008Z"
          transform="translate(-318.588 -377.788)"
          fill="#01214a"
        />
        <path
          id="Path_2437"
          data-name="Path 2437"
          d="M425.55,400h-2.183l.251,2.008H426.3Z"
          transform="translate(-364.992 -349.804)"
          fill="#01214a"
        />
        <path
          id="Path_2438"
          data-name="Path 2438"
          d="M420.546,368h-1.179l.251,2.008H421.3Z"
          transform="translate(-361.494 -321.82)"
          fill="#01214a"
        />
        <path
          id="Path_2439"
          data-name="Path 2439"
          d="M410.3,440h2.008v1H410.3Z"
          transform="translate(-353.569 -384.784)"
          fill="#01214a"
        />
        <path
          id="Path_2440"
          data-name="Path 2440"
          d="M362.3,440h2.008v1H362.3Z"
          transform="translate(-311.592 -384.784)"
          fill="#01214a"
        />
      </g>
    </g>
  </svg>
)
