import React, { useState } from "react"

import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby"

import Logo from "../../static/logo.svg"

import { Box, Flex, Stack, Link, useColorModeValue } from "@chakra-ui/react"

import DesktopNav from "./navigation/desktop/DesktopNav"
import SearchInputDesktop from "./navigation/desktop/SearchInputDesktop"

import MobileNav from "./navigation/mobile/MobileNav"
import SearchInputMobile from "./navigation/mobile/SearchInputMobile"

export const NAV_ITEMS = ["Blicke", "Bereiche", "Daten", "Projekte"]

export default function Navigation({ pageType }) {
  const [isOpen, setIsOpen] = useState(false)

  const data = useStaticQuery(query)

  return (
    <>
      <Box
        as="nav"
        borderBottom={{ base: 0, md: 1 }}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
      >
        <Box maxW="7xl" mx="auto">
          <Flex
            color={useColorModeValue("gray.600", "white")}
            minH={"80px"}
            pt={{ base: 4, md: "48px" }}
            pb={4}
            px={{ base: 4 }}
            display={{ base: "none", md: "flex" }}
            justifyContent="space-between"
          >
            <Link
              as={GatsbyLink}
              to="/"
              marginTop={{ base: 0, md: -7 }}
              mr={2}
              aria-label="Zur Homepage"
            >
              <Logo />
            </Link>

            <Flex display={{ base: "none", md: "flex" }}>
              <DesktopNav data={data} pageType={pageType} />
            </Flex>

            <Stack
              flex={{ base: 1, md: 0 }}
              justify={"flex-end"}
              direction={"row"}
              spacing={6}
            >
              <SearchInputDesktop searchValues={data.search} />
            </Stack>
          </Flex>
          <Flex
            display={{ base: "flex", md: "none" }}
            justify="space-between"
            align="center"
            bg={isOpen ? "dark_blue" : undefined}
            transition="background-color 0.1s ease-out"
            minH={"90px"}
          >
            <Link
              as={GatsbyLink}
              to="/"
              ml={2}
              pr={{ base: 6, sm: null }}
              my={2}
              aria-label="Zur Homepage"
            >
              <Box sx={{ "> *": { maxWidth: "100%" } }} ml={2} mr={3}>
                <MobileLogo />
              </Box>
            </Link>
            <MobileNav data={data} isOpen={isOpen} setIsOpen={setIsOpen} />
          </Flex>
        </Box>
      </Box>
      <SearchInputMobile searchValues={data.search} />
    </>
  )
}

const MobileLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="52.784"
    height="49.638"
    viewBox="0 0 52.784 49.638"
  >
    <g id="Group_2170" data-name="Group 2170" transform="translate(0 0)">
      <path
        id="Pfad_1458"
        data-name="Pfad 1458"
        d="M7380.547,2406l-7.93,49.638h-44.854l7.93-49.638Z"
        transform="translate(-7327.763 -2405.995)"
        fill="#005a9a"
      />
      <path
        id="Pfad_1459"
        data-name="Pfad 1459"
        d="M7519.934,2428.71h-5.109l9.814,7.807-.946-4.911,4.551-2.9h-5.109l-.947-4.91Z"
        transform="translate(-7479.514 -2420.622)"
        fill="#ffd500"
      />
      <path
        id="Pfad_1460"
        data-name="Pfad 1460"
        d="M7396.486,2466.4l-9.64-15.093h-8.16l-5.193,33.365h11.125l2.226-14.3v-.795l9.643,15.091h8.16l3.209-20.612-9.814-8.435-1.555,9.983Z"
        transform="translate(-7365.332 -2443.224)"
        fill="#ffd500"
      />
    </g>
  </svg>
)

const query = graphql`
  query Navbar {
    search: localSearchPages {
      index
      store
    }
    bereiche: allWpPage(
      filter: {
        status: { eq: "publish" }
        pagebuilder: { fixeElementeTop: { typ: { name: { eq: "Bereiche" } } } }
      }
    ) {
      nodes {
        ...NavbarFragment
      }
    }
    daten: allWpPage(
      filter: {
        status: { eq: "publish" }
        pagebuilder: { fixeElementeTop: { typ: { name: { eq: "Daten" } } } }
      }
    ) {
      nodes {
        ...NavbarFragment
      }
    }
    projekte: allWpPage(
      filter: {
        status: { eq: "publish" }
        pagebuilder: { fixeElementeTop: { typ: { name: { eq: "Projekte" } } } }
      }
    ) {
      nodes {
        ...NavbarFragment
      }
    }
    blicke: allWpPage(
      filter: {
        status: { eq: "publish" }
        pagebuilder: { fixeElementeTop: { typ: { name: { eq: "Blicke" } } } }
      }
    ) {
      nodes {
        ...NavbarFragment
      }
    }
  }

  fragment NavbarFragment on WpPage {
    id
    title
    uri
    pagebuilder {
      fixeElementeTop {
        teaserText
        jahr
        kategorie
      }
    }
  }
`
