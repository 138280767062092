import React, { useState, useEffect, useRef } from "react"

import { Link as GatsbyLink } from "gatsby"

import {
  Box,
  Button,
  Input,
  HStack,
  VStack,
  Link,
  Text,
  Flex,
  Center,
  FormControl,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerContent,
} from "@chakra-ui/react"
import CategorySVGSwitch from "../../../../config/svgs"

import { SearchIcon } from "../../svgs"

import { useFlexSearch } from "react-use-flexsearch"

const SearchInputMobile = ({ searchValues }) => {
  const [query, setQuery] = useState("")
  const results = useFlexSearch(query, searchValues.index, searchValues.store)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()

  const handleChange = event => setQuery(event.target.value)

  const onInputSubmit = e => {
    e.preventDefault()
    if (e.type === "submit") {
      onOpen()
    }
  }

  const close = () => {
    setQuery("")
    onClose()
  }

  useEffect(() => {
    setQuery("")
  }, [])

  return (
    <Box
      display={{ md: "none" }}
      position="fixed"
      zIndex="20"
      bottom={0}
      left={0}
      right={0}
      bg="white"
      overflowY="scroll"
    >
      <VStack spacing={0} alignItems="flex-start">
        <Drawer
          isOpen={isOpen}
          placement="bottom"
          onClose={onClose}
          finalFocusRef={btnRef}
          focusBorderColor="dark_blue"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader
              pb={1}
              pl={2}
              fontSize="1.0625rem"
              lineHeight="1.3125rem"
            >
              Suchergebnisse:
            </DrawerHeader>
            <DrawerCloseButton />
            <Box pb={1} maxHeight="94vh" overflowY="scroll" w="100%">
              {results.length > 0 ? (
                results.map((node, index) => {
                  const t = node.type || ""
                  const type = t.toLowerCase() === "blicke" ? "blicke" : t
                  return (
                    <Link
                      as={GatsbyLink}
                      to={node.uri}
                      onClick={close}
                      key={node.id}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        dir="row"
                        borderBottomWidth={
                          !(index === results.length - 1) && "1px"
                        }
                        borderBottomColor="dark_blue"
                      >
                        <Center
                          width="100%"
                          maxW="40px"
                          maxH="55px"
                          flexShrink={0}
                          pl={1}
                        >
                          {node.kategorie &&
                            node.kategorie[0] &&
                            CategorySVGSwitch(node.kategorie[0])}
                        </Center>
                        <Box
                          px={2}
                          flex={1}
                          minW={"0px"}
                          flexShrink={1}
                          pt="4px"
                        >
                          <Text
                            as="h5"
                            fontSize="1.0625rem"
                            lineHeight="1.3125rem"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                          >
                            {node.title}
                          </Text>
                          <Text
                            fontSize="0.875rem"
                            lineHeight="1.3125rem"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            sx={{
                              "*": {
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              },
                            }}
                            dangerouslySetInnerHTML={{
                              __html: node.excerpt ? node.excerpt : null,
                            }}
                          />
                        </Box>
                        <VStack
                          w="100%"
                          maxW="60px"
                          flexShrink={0}
                          h="55px"
                          borderBottom="1px solid white"
                          align="stretch"
                          spacing={0}
                        >
                          <Center w="100%" flex={1} bgColor="dark_blue">
                            <Text
                              color="white"
                              as="span"
                              fontFamily="CocoSharpL-Bold"
                              fontSize="0.75rem"
                              textDecor="none"
                              pt="4px"
                            >
                              {type}
                            </Text>
                          </Center>
                          <Center w="100%" flex={1} bgColor="primary">
                            <Text
                              color="white"
                              as="span"
                              fontFamily="CocoSharpL-Bold"
                              fontSize="1rem"
                              textDecor="none"
                              pt="4px"
                            >
                              {node.jahr}
                            </Text>
                          </Center>
                        </VStack>
                      </Flex>
                    </Link>
                  )
                })
              ) : (
                <Box w="100%" p={2}>
                  <Text>
                    Wir konnten keine Ergebnisse für Ihre Suche finden.
                  </Text>
                </Box>
              )}
            </Box>
          </DrawerContent>
        </Drawer>
        <FormControl as="form" onSubmit={onInputSubmit}>
          <HStack
            w="100%"
            h="50px"
            spacing={0}
            boxShadow="0 0px 10px 0px #33333330"
          >
            <Input
              placeholder="Suchbegriff eingeben"
              className="mobile-search-input"
              height="100%"
              onChange={handleChange}
              onSubmit={onInputSubmit}
              value={query}
              borderTop="1px solid #00000080"
              borderRadius={0}
              color="dark_blue"
              letterSpacing="1px"
              focusBorderColor="dark_blue"
              _focus={{ borderWidth: "1px" }}
              _hover={{ borderColor: "dark_blue" }}
            />
            <Button
              px={4}
              color="white"
              bg="dark_blue"
              height="100%"
              ref={btnRef}
              onClick={onOpen}
              borderRadius={0}
              _hover={{
                opacity: "0.95",
              }}
              aria-label="Suche starten"
            >
              <SearchIcon color="white" />
            </Button>
          </HStack>
        </FormControl>
      </VStack>
    </Box>
  )
}

export default SearchInputMobile
