import React, { useState } from "react"

import { Link as GatsbyLink } from "gatsby"

import {
  Box,
  Text,
  Flex,
  Grid,
  Link,
  VStack,
  HStack,
  Center,
} from "@chakra-ui/react"

import CategorySVGSwitch from "../../../../../config/svgs"
import { order } from "../../../../../config/categories"

import { Header } from "./components"
import { desktopNavBgColor } from "./desktop-nav-constants"
import { StaticImage } from "gatsby-plugin-image"

const SinglePageMenu = ({ data, isBlickeMenu = false }) => {
  // Create array with unique years of all articles
  const years = [
    ...new Set(data.map(item => item.pagebuilder.fixeElementeTop.jahr)),
  ]
  const sortedYears = years.length > 1 ? years.sort((a, b) => b - a) : years

  const [currentYear, setCurrentYear] = useState(years[0])

  // Filter data by currently selected year and sort by category order defined in user config
  const filteredData = data
    .filter(a => a.pagebuilder.fixeElementeTop.jahr === currentYear)
    .filter(a => a.pagebuilder.fixeElementeTop.kategorie !== null)
    .sort(
      (a, b) =>
        order[a.pagebuilder.fixeElementeTop.kategorie[0]] -
        order[b.pagebuilder.fixeElementeTop.kategorie[0]]
    )

  return (
    <Box height="100%" w="100%" backgroundColor={desktopNavBgColor}>
      <Header
        years={sortedYears}
        currentYear={currentYear}
        setCurrentYear={setCurrentYear}
      />

      <Flex
        minH="200px"
        maxH="80vh"
        overflowY="scroll"
        align="flex-start"
        p="8"
        pt={0}
      >
        <Grid
          templateColumns="repeat(4, 1fr)"
          gap={0}
          h="auto"
          overflow="hidden"
        >
          {filteredData.map(item => {
            return (
              <GridItemContainer key={item.id} uri={item.uri}>
                {isBlickeMenu ? (
                  <BlickeContentType item={item} />
                ) : (
                  <BereicheContentType item={item} />
                )}
              </GridItemContainer>
            )
          })}
          {isBlickeMenu && (
            <GridItemContainer uri="/blicke-bestellen/" p={0} h="100%">
              <BlickeMagazin jahr={currentYear} />
            </GridItemContainer>
          )}
        </Grid>
      </Flex>
    </Box>
  )
}

const BlickeMagazin = ({ jahr }) => {
  return (
    <Flex direction="column" height="100%">
      {jahr === 2021 ? (
        <StaticImage
          src={"./blicke-2021.jpg"}
          alt="Blicke Magazin"
          imgStyle={{
            height: "100%",
          }}
          style={{ flexGrow: 1 }}
        />
      ) : (
        <StaticImage
          src={"./blicke-2022.jpg"}
          alt="Blicke Magazin"
          imgStyle={{
            height: "100%",
          }}
          style={{ flexGrow: 1 }}
        />
      )}
      <Center pb={1} pt={2} sx={{ backgroundColor: "primary" }}>
        <Text
          textAlign="center"
          as="p"
          color="white"
          fontWeight="bold"
          fontFamily="CocoSharpL-Bold"
        >
          blicke{" "}
          <Text as="span" color="secondary">
            {jahr}
          </Text>{" "}
          <Text
            as="span"
            fontFamily="CocoSharpL-Regular"
            fontWeight={400}
            color="white"
          >
            bestellen
          </Text>
        </Text>
      </Center>
    </Flex>
  )
}

const GridItemContainer = ({ children, uri, ...props }) => (
  <Flex
    minH="200px"
    outline="1px solid white"
    minW="200px"
    _hover={{ backgroundColor: "primary" }}
  >
    <Link
      as={GatsbyLink}
      to={uri}
      w="100%"
      p={2}
      _hover={{ textDecoration: "none" }}
      _focus={{
        opacity: 0.8,
        outline: "-webkit-focus-ring-color auto 3px",
      }}
      {...props}
    >
      {children}
    </Link>
  </Flex>
)

export default SinglePageMenu

const BereicheContentType = ({ item }) => {
  const { kategorie } = item.pagebuilder.fixeElementeTop

  return (
    <VStack align="center" justify="center" h="100%">
      <Box w="70px" h="70px">
        {kategorie[0] && CategorySVGSwitch(kategorie[0])}
      </Box>

      <Text
        as="span"
        color="white"
        textAlign="center"
        fontSize={"1.375rem"}
        lineHeight={"1.6rem"}
        px={2}
        wordBreak="break-word"
      >
        {item.title}
      </Text>
    </VStack>
  )
}

const BlickeContentType = ({ item }) => {
  const kategorie = item.pagebuilder?.fixeElementeTop?.kategorie
  const teaser = item.pagebuilder?.fixeElementeTop?.teaserText

  return (
    <VStack py={4} align="center" justify="flex-start" h="100%">
      <Text
        as="span"
        fontFamily="kepler-std"
        color="white"
        textAlign="center"
        fontSize="1.4375rem"
        lineHeight="1.5rem"
        fontWeight="semibold"
        px={2}
        wordBreak="break-word"
        display="block"
        flex={1}
      >
        {item.title}
      </Text>

      <Text as="span" color="white" fontSize="1.4375rem" lineHeight="0.8rem">
        -
      </Text>

      <Text
        as="span"
        color="white"
        textAlign="center"
        fontSize={"md"}
        lineHeight={"1.25rem"}
        px={2}
        noOfLines={3}
      >
        {teaser}
      </Text>

      <HStack justify="center" pt={2}>
        {kategorie.map((item, index) => {
          return (
            <Box w="40px" h="40px" key={index}>
              {item && CategorySVGSwitch(item)}
            </Box>
          )
        })}
      </HStack>
    </VStack>
  )
}
