import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { colors } from "../../../config/categories"

import {
  Box,
  Heading,
  Text,
  Flex,
  Grid,
  GridItem,
  Center,
} from "@chakra-ui/react"

import { Arrow } from "./Auszug"

const AuszugTwo = ({ data }) => {
  const GridItems = () =>
    data.slice(0, 5).map((item, index) => {
      const { title, uri, featuredImage } = item
      const { teaserText, kategorie } = item.pagebuilder.fixeElementeTop
      const image = featuredImage && getImage(featuredImage.node.localFile)

      return (
        <GridItem
          key={uri}
          maxHeight="100%"
          outline="1px solid var(--chakra-colors-dark_blue)"
          role="group"
          position="relative"
          overflow="hidden"
          gridArea={{ lg: `child-${index}` }}
        >
          <Link to={uri}>
            <Box
              height="100%"
              pt={8}
              pb={{ lg: index === 0 ? 8 : 4 }}
              px={{ md: 4 }}
              role="group"
              position="relative"
              overflow="hidden"
            >
              <Flex
                direction={{
                  base: "column",
                  lg: index === 0 ? "row" : "column",
                }}
                height="100%"
                justify="space-between"
              >
                <Box
                  pb={4}
                  width={{ lg: index === 0 && "50%" }}
                  mr={{ lg: index === 0 && 16 }}
                >
                  {kategorie ? (
                    <Heading
                      as="span"
                      fontFamily="CocoSharp-Bold"
                      fontSize="0.875rem"
                      letterSpacing="1px"
                      textTransform="uppercase"
                      color="dark_blue"
                    >
                      {kategorie.length > 1
                        ? kategorie.join(" & ")
                        : kategorie[0]}
                    </Heading>
                  ) : (
                    <div style={{ height: "19px" }} />
                  )}
                  <Heading
                    as="h3"
                    mb={4}
                    fontFamily="CocoSharp-Bold"
                    fontSize="1.875rem"
                    lineHeight="1.875rem"
                    color="dark_blue"
                    mt={2}
                  >
                    {title}
                  </Heading>
                  <Text
                    overflow="hidden"
                    whiteSpace="normal"
                    color="dark_blue"
                    lineHeight="1.3125rem"
                    dangerouslySetInnerHTML={{
                      __html: teaserText,
                    }}
                  />
                </Box>
                {image && (
                  <Box
                    height="100%"
                    overflow="hidden"
                    maxH={index !== 0 && "250px"}
                  >
                    <GatsbyImage
                      image={image}
                      style={{
                        height: "100%",
                        overflow: "hidden",
                        width: "100%",
                      }}
                      imgStyle={{
                        overflow: "hidden",
                        objectFit: index > 0 ? "contain" : "cover",
                        height: "100%",
                      }}
                      alt={featuredImage.node.altText}
                    />
                  </Box>
                )}
              </Flex>
              {/* Hover Screen */}
              <Box
                display="none"
                _groupHover={{
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  backgroundColor:
                    `${colors[kategorie[0]]}90` || "rgba(205, 239, 205, 0.9)",
                  backdropFilter: "blur(20px)",
                }}
              >
                {/* <VStack align="stretch" height="100%" p={4}> */}
                {/* <Flex
                    flex={3}
                    justifyContent="center"
                    alignItems="center"
                    paddingTop={16}
                  >
                    {kategorie && (
                      <Heading
                        textAlign="center"
                        fontFamily="CocoSharp-Bold"
                        fontSize="1.375rem"
                        lineHeight="1.4375rem"
                        px={4}
                      >
                        {kategorie[0]}
                      </Heading>
                    )}
                  </Flex> */}
                <Center
                  flex={1}
                  flexDirection="column"
                  height="100%"
                  alignItems="center"
                >
                  <Text
                    fontSize="xl"
                    color="dark_blue"
                    textDecoration="underline"
                    textDecorationThickness="1px"
                    textDecorationColor="dark_blue"
                    mb={3}
                  >
                    Mehr erfahren
                  </Text>
                  <Arrow />
                </Center>
                {/* </VStack> */}
              </Box>
            </Box>
          </Link>
        </GridItem>
      )
    })

  return (
    <Box as="section" py={12}>
      <Heading as="h2" mb={8} textAlign="center" fontFamily="CocoSharp-Bold">
        Ausgewählte Themen aus Projekte &amp; Daten
      </Heading>
      <Grid
        templateRows={{ md: "repeat(3, 1fr)", lg: "1fr 0.2fr 1fr" }}
        templateColumns={{ md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
        gridTemplateAreas={{
          lg: '"child-0 child-0 child-1" "child-2 child-3 child-1" "child-2 child-3 child-4"',
        }}
        overflow="hidden"
        gridGap="1px"
        marginRight="-2px"
      >
        <GridItems />
      </Grid>
    </Box>
  )
}

export default AuszugTwo
