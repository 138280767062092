import React, { useDeferredValue } from "react"

import { ActiveIndicatorIcon, BlickeSvg } from "../../svgs"

import { Link as GatsbyLink } from "gatsby"

import {
  Box,
  Text,
  Stack,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Portal,
  useDisclosure,
} from "@chakra-ui/react"

import { SinglePageMenu, GroupByCategoryMenu } from "./submenus"

import { NAV_ITEMS } from "../../Navbar"
import { desktopNavBgColor } from "./submenus/desktop-nav-constants"

const DesktopNav = ({ data, pageType }) => {
  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem, index) => (
        <NavItem
          key={index}
          navItem={navItem}
          pageType={pageType}
          data={data}
        />
      ))}
      <Box>
        <Link
          as={GatsbyLink}
          to="/ueber-uns"
          p={{ md: 0, lg: 4 }}
          fontSize="lg"
          position="relative"
          color="dark_blue"
          _hover={{
            textDecoration: "none",
          }}
        >
          Über
        </Link>
      </Box>
    </Stack>
  )
}

export default DesktopNav

const NavItem = ({ pageType, navItem, data }) => {
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: false })

  const deferredOpen = useDeferredValue(isOpen, { timeoutMs: 0 })

  const isActiveLink = pageType === navItem

  return (
    <Box key={navItem}>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        trigger="hover"
        variant="responsive"
      >
        <PopoverTrigger>
          <Link
            href="#"
            p={{ md: 0, lg: 4 }}
            position="relative"
            _hover={{
              textDecoration: "none",
            }}
            aria-label={navItem === "Blicke" ? "Blicke" : undefined}
          >
            {navItem === "Blicke" ? (
              <Box pt="2px" w="3.7rem" display="inline-block">
                <BlickeSvg />
              </Box>
            ) : (
              <Text as="span" fontSize="lg">
                {navItem}
              </Text>
            )}
            <Box
              className={isActiveLink && "nav_active"}
              top={{ md: "-57px", lg: "-42px" }}
              display={isActiveLink ? "flex" : "none"}
            >
              <ActiveIndicatorIcon />
            </Box>

            <Box
              position="absolute"
              display={isOpen ? "flex" : "none"}
              opacity={deferredOpen ? 1 : 0}
              bottom="-34px"
              width="90px"
              height="100px"
              left="50%"
              zIndex={100}
              transform="translateX(-50%) rotate(180deg)"
              transition={"opacity 0.2s ease-in-out"}
            >
              <ActiveIndicatorIcon color={desktopNavBgColor} />
            </Box>
          </Link>
        </PopoverTrigger>
        <Portal>
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100vw"
            height={0}
            zIndex={1000}
          >
            <PopoverContent
              border={0}
              display={{ base: "none", md: "flex" }}
              position="absolute"
              sx={{
                position: "absolute",
              }}
              boxShadow="none"
              px={4}
              w="100%"
              maxWidth="100%"
              maxW="78rem"
              left="50%"
              top="21px"
              bg="none"
              transform="translateX(-50%)!important"
            >
              <MenuSwitch data={data} value={navItem} />
            </PopoverContent>
          </Box>
        </Portal>
      </Popover>
    </Box>
  )
}

const MenuSwitch = ({ data, value }) => {
  const articles = data[value.toLowerCase()].nodes
  switch (value) {
    case "Projekte":
      return <GroupByCategoryMenu data={articles} type={value} />
    case "Daten":
      return <GroupByCategoryMenu data={articles} type={value} disableYearSelect />
    case "Bereiche":
      return <SinglePageMenu data={articles} />
    case "Blicke":
      return <SinglePageMenu isBlickeMenu data={articles} />
    default:
      return null
  }
}
