import React, { useMemo, useState } from "react"

import { Link as GatsbyLink } from "gatsby"

import {
  Box,
  HStack,
  Text,
  Link,
  List,
  ListItem,
  ListIcon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react"

import { ArrowForwardIcon } from "@chakra-ui/icons"

import CategorySVGSwitch from "../../../../../config/svgs"
import { order } from "../../../../../config/categories"

import { Header } from "./components"
import { desktopNavBgColor } from "./desktop-nav-constants"
import { formatFunnelUri } from "../../../../utils/format-funnel-uri"

const GroupByCategoryMenu = ({ data, type, disableYearSelect = false }) => {
  const years = [
    ...new Set(data.map(item => item.pagebuilder.fixeElementeTop.jahr)),
  ]
  const sortedYears = years.length > 1 ? years.sort((a, b) => b - a) : years

  const [currentYear, setCurrentYear] = useState(years[0])

  const { columnA, columnB } = useMemo(() => {
    const categorizedData = {}

    data.forEach(item => {
      item.pagebuilder.fixeElementeTop.kategorie.forEach(cat =>
        categorizedData[cat]
          ? categorizedData[cat].push(item)
          : (categorizedData[cat] = [item])
      )
    })

    const t = Object.entries(categorizedData).sort(
      (a, b) => order[a[0]] - order[b[0]]
    )

    const half = Math.round(t.length / 2)

    const columnA = t.slice(0, half)
    const columnB = t.slice(half)

    return { columnA, columnB }
  }, [data])

  return ( 
    <Box
      height="100%"
      w="100%"
      backgroundColor={desktopNavBgColor}
      backdropFilter="blur(5px)"
    >
      {!disableYearSelect && (
        <Header
          years={sortedYears}
          currentYear={currentYear}
          setCurrentYear={setCurrentYear}
        />
      )}
      <Accordion
        allowToggle
        allowMultiple
        sx={{
          maxHeight: "80vh",
          overflowY: "scroll",
        }}
      >
        <HStack
          spacing={0}
          wrap="nowrap"
          align="flex-start"
          h="100%"
          position="relative"
          p="8"
          pt={0}
        >
          <ColumnContainer>
            {columnA.map(([key, value]) => (
              <ColumnItem
                key={key}
                itemKey={key}
                value={value}
                currentYear={currentYear}
                type={type}
                disableYearSelect={disableYearSelect}
              />
            ))}
          </ColumnContainer>
          <Box
            sx={{
              position: "absolute",
              left: "50%",
              top: 0,
              bottom: "2rem",
              zIndex: 100,
              // height: "100%",
              backgroundColor: "#fff",
              transform: "translateX(-50%)",
              width: "1px",
              margin: "2px",
              alignSelf: "stretch",
              display: "flex",
            }}
          />
          <ColumnContainer>
            {columnB.map(([key, value]) => (
              <ColumnItem
                key={key}
                itemKey={key}
                value={value}
                currentYear={currentYear}
                type={type}
                disableYearSelect={disableYearSelect}
              />
            ))}
          </ColumnContainer>
        </HStack>
      </Accordion>
    </Box>
  )
}

const ColumnContainer = ({ children, sx }) => (
  <Box
    sx={{
      overflow: "hidden",

      // FLEX
      width: "100%",
      display: "flex",
      flexDirection: "column",
      // alignItems: "flex-start",

      ...(sx || {}),
    }}
  >
    {children}
  </Box>
)

const ColumnItem = ({ currentYear, itemKey, value, type, disableYearSelect = false }) => {
  const filtered = disableYearSelect ? value : value.filter(
    item => currentYear === item.pagebuilder.fixeElementeTop.jahr
  )

  if (filtered.length < 1) {
    return null
  }

  return (
    <Box outline="1px solid white" p={4}>
      <AccordionItem>
        {({ isExpanded }) => (
          <>
            <AccordionButton
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <HStack justifyContent="flex-start" alignItems="center" w="100%">
                <Box
                  w="50px"
                  h="50px"
                  flex="0 0 auto"
                  flexShrink={0}
                  ml={4}
                  mr={2}
                >
                  {CategorySVGSwitch(itemKey)}
                </Box>
                <Text
                  flexGrow={1}
                  display="block"
                  as="span"
                  color="white"
                  fontSize="lg"
                  align="left"
                  lineHeight="1.4rem"
                  wordBreak="break-word"
                >
                  {itemKey}
                </Text>

                <Box
                  sx={{
                    transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease-in-out", // smooth transition
                  }}
                >
                  <AccordionArrow />
                </Box>
              </HStack>
            </AccordionButton>

            <AccordionPanel pb={4}>
              <List spacing={2} pl={6}>
                <ListItem
                  color="white"
                  fontSize="sm"
                  fontWeight="bold"
                  lineHeight="1rem"
                  _focus={{
                    outline: "-webkit-focus-ring-color auto 1px",
                  }}
                >
                  <ListIcon as={ArrowForwardIcon} color="white" ml={-6} />
                  <Link
                    as={GatsbyLink}
                    to={formatFunnelUri(type, disableYearSelect ? undefined :  currentYear, itemKey)}
                  >
                    Alle {type} im Überblick
                  </Link>
                </ListItem>
                {filtered.map(item => (
                  <ListItem
                    color="white"
                    key={item.uri}
                    fontSize="sm"
                    lineHeight="1rem"
                    _focus={{
                      outline: "-webkit-focus-ring-color auto 1px",
                    }}
                  >
                    <ListIcon as={ArrowForwardIcon} color="white" ml={-6} />
                    <Link as={GatsbyLink} to={item.uri}>
                      {item.title}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Box>
  )
}

const AccordionArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.121"
    height="21.414"
    viewBox="0 0 12.121 21.414"
  >
    <g
      id="Group_2150"
      data-name="Group 2150"
      transform="translate(-383.939 -414.849)"
    >
      <path
        id="Pfad_1589"
        data-name="Pfad 1589"
        d="M437.646,417.556l10,10-10,10"
        transform="translate(-53 -2)"
        fill="none"
        stroke="#fff"
        strokeWidth="2"
      />
    </g>
  </svg>
)

export default GroupByCategoryMenu
